export default {
  namespaced: true,
  state () {
    return {
      data: {
        // 模拟的患者笔记数据
        patientNotes: {
          total: 15, // 总共15条数据(5个月每月3条)
          list: [
            // 2025年2月的3条记录
            ...Array(3).fill().map((_, i) => ({
              recordDate: `2025-02-${String(28 - i).padStart(2, '0')} ${String(Math.floor(Math.random() * 24)).padStart(2, '0')}:${String(Math.floor(Math.random() * 60)).padStart(2, '0')}`,
              doctorName: ['张医生', '李医生', '王医生'][i % 3],
              content: `(演示数据)患者记录: ${['情况稳定，继续保持', '恢复良好，建议加强锻炼', '复查结果正常，保持规律作息'][i % 3]}`,
              attachments: i % 3 === 0 ? [
                {
                  type: 'image',
                  url: 'https://cdn.keihong.tech/img/20250223225401214.png'
                },
                {
                  type: 'video',
                  url: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/patient_notes/videos/2025-02/20250224144554_29415382.mp4'
                }
              ] : (i % 3 === 1 ? [
                {
                  type: 'image',
                  url: 'https://cdn.keihong.tech/img/20250223225401214.png'
                }
              ] : undefined)
            })),
            // 2025年1月的3条记录
            ...Array(3).fill().map((_, i) => ({
              recordDate: `2025-01-${String(31 - i).padStart(2, '0')} ${String(Math.floor(Math.random() * 24)).padStart(2, '0')}:${String(Math.floor(Math.random() * 60)).padStart(2, '0')}`,
              doctorName: ['张医生', '李医生', '王医生'][i % 3],
              content: `(演示数据)患者记录: ${['情况稳定，继续保持', '恢复良好，建议加强锻炼', '复查结果正常，保持规律作息'][i % 3]}`,
              attachments: i % 3 === 0 ? [
                {
                  type: 'image',
                  url: 'https://cdn.keihong.tech/img/20250223225401214.png'
                },
                {
                  type: 'video',
                  url: 'https://cdn.keihong.tech/img/20250224000036532.mp4'
                }
              ] : (i % 3 === 1 ? [
                {
                  type: 'image',
                  url: 'https://cdn.keihong.tech/img/20250223225401214.png'
                }
              ] : undefined)
            })),
            // 2024年12月的3条记录
            ...Array(3).fill().map((_, i) => ({
              recordDate: `2024-12-${String(31 - i).padStart(2, '0')} ${String(Math.floor(Math.random() * 24)).padStart(2, '0')}:${String(Math.floor(Math.random() * 60)).padStart(2, '0')}`,
              doctorName: ['张医生', '李医生', '王医生'][i % 3],
              content: `(演示数据)患者记录: ${['情况稳定，继续保持', '恢复良好，建议加强锻炼', '复查结果正常，保持规律作息'][i % 3]}`,
              attachments: i % 3 === 0 ? [
                {
                  type: 'image',
                  url: 'https://cdn.keihong.tech/img/20250223225401214.png'
                },
                {
                  type: 'video',
                  url: 'https://cdn.keihong.tech/img/20250224000036532.mp4'
                }
              ] : (i % 3 === 1 ? [
                {
                  type: 'image',
                  url: 'https://cdn.keihong.tech/img/20250223225401214.png'
                }
              ] : undefined)
            })),
            // 2024年11月的3条记录
            ...Array(3).fill().map((_, i) => ({
              recordDate: `2024-11-${String(30 - i).padStart(2, '0')} ${String(Math.floor(Math.random() * 24)).padStart(2, '0')}:${String(Math.floor(Math.random() * 60)).padStart(2, '0')}`,
              doctorName: ['张医生', '李医生', '王医生'][i % 3],
              content: `(演示数据)患者记录: ${['情况稳定，继续保持', '恢复良好，建议加强锻炼', '复查结果正常，保持规律作息'][i % 3]}`,
              attachments: i % 3 === 0 ? [
                {
                  type: 'image',
                  url: 'https://cdn.keihong.tech/img/20250223225401214.png'
                },
                {
                  type: 'video',
                  url: 'https://cdn.keihong.tech/img/20250224000036532.mp4'
                }
              ] : (i % 3 === 1 ? [
                {
                  type: 'image',
                  url: 'https://cdn.keihong.tech/img/20250223225401214.png'
                }
              ] : undefined)
            })),
            // 2024年10月的3条记录
            ...Array(3).fill().map((_, i) => ({
              recordDate: `2024-10-${String(31 - i).padStart(2, '0')} ${String(Math.floor(Math.random() * 24)).padStart(2, '0')}:${String(Math.floor(Math.random() * 60)).padStart(2, '0')}`,
              doctorName: ['张医生', '李医生', '王医生'][i % 3],
              content: `(演示数据)患者记录: ${['情况稳定，继续保持', '恢复良好，建议加强锻炼', '复查结果正常，保持规律作息'][i % 3]}`,
              attachments: i % 3 === 0 ? [
                {
                  type: 'image',
                  url: 'https://cdn.keihong.tech/img/20250223225401214.png'
                },
                {
                  type: 'video',
                  url: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/patient_notes/videos/2025-02/20250224144554_29415382.mp4'
                }
              ] : (i % 3 === 1 ? [
                {
                  type: 'image',
                  url: 'https://cdn.keihong.tech/img/20250223225401214.png'
                }
              ] : undefined)
            }))
          ]
        }
      }
    }
  },
  mutations: {
    // 添加数据到data里面，是添加一个对象到子属性
    addData (state, payload) {
      state.data[payload.key] = payload.value
    },

    // 添加新的笔记记录
    addNote (state, noteData) {
      // 确保附件数据结构正确
      const attachments = noteData.attachments?.map(attachment => {
        const processedAttachment = {
          type: attachment.type,
          url: attachment.url
        }

        // 如果是视频,确保有封面
        if (attachment.type === 'video') {
          processedAttachment.thumbnail = attachment.thumbnail
        }

        return processedAttachment
      })

      // 将新记录添加到列表开头
      state.data.patientNotes.list.unshift({
        ...noteData,
        attachments
      })

      // 更新总数
      state.data.patientNotes.total++
    }
  },
  getters: {
    // 获取分页后的笔记列表
    getPagedNotes: (state) => (page, pageSize) => {
      const startIndex = (page - 1) * pageSize
      const endIndex = Math.min(startIndex + pageSize, state.data.patientNotes.total)
      return state.data.patientNotes.list.slice(startIndex, endIndex)
    },
    // 获取笔记总数
    getNotesTotal: (state) => {
      return state.data.patientNotes.total
    }
  }
}
