<template>
  <div class="assessment-report">
    <!-- 页面头部 -->
    <div class="report-header">
      <h1 class="report-title">脊柱弯曲异常筛查结果记录表</h1>
      <div class="report-meta">
        <div class="meta-item">
          <span class="meta-label">评估日期：</span>
          <span class="meta-value">{{ formatDate(assessmentDate) }}</span>
        </div>
        <div class="meta-item" v-if="patientInfo.name">
          <span class="meta-label">姓名：</span>
          <span class="meta-value">{{ patientInfo.name }}</span>
        </div>
        <div class="meta-item" v-if="patientInfo.age">
          <span class="meta-label">年龄：</span>
          <span class="meta-value">{{ patientInfo.age }}岁</span>
        </div>
        <div class="meta-item" v-if="patientInfo.gender">
          <span class="meta-label">性别：</span>
          <span class="meta-value">{{ patientInfo.gender }}</span>
        </div>
      </div>
    </div>

    <!-- 评估内容区域 -->
    <div class="report-content">
      <!-- 基本信息部分 -->
      <div class="report-section">
        <h2 class="section-title">基本信息</h2>
        <div class="section-content">
          <div class="info-item" v-for="(item, index) in getQuestionsBySection('基本信息')" :key="'basic-'+index">
            <span class="info-label">{{ formatTitle(item.title) }}</span>
            <span class="info-value">{{ formatAnswer(item) }}</span>
          </div>
        </div>
      </div>

      <!-- 脊柱侧弯检查部分 -->
      <div class="report-section">
        <h2 class="section-title">一、脊柱侧弯检查</h2>

        <!-- 一般检查 -->
        <h3 class="subsection-title">1. 一般检查</h3>
        <div class="table-container">
          <table class="assessment-table">
            <tbody>
              <tr v-for="(item, index) in getQuestionsByTitle('一般检查')" :key="'general-'+index">
                <td class="table-label">{{ formatTitle(item.title) }}</td>
                <td class="table-value">{{ formatAnswer(item) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 前屈试验 -->
        <h3 class="subsection-title">2. 前屈试验</h3>
        <div class="table-container">
          <table class="assessment-table">
            <thead>
              <tr>
                <th>检查部位</th>
                <th>侧弯情况</th>
                <th>躯干旋转角度(ATR)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>胸段</td>
                <td>{{ formatAnswer(getQuestionByTitle('2.1 胸段')) }}</td>
                <td>{{ formatAnswer(getQuestionByTitle('2.1 胸段-躯干旋转角度（ATR）')) }}</td>
              </tr>
              <tr>
                <td>腰胸段</td>
                <td>{{ formatAnswer(getQuestionByTitle('2.2 腰胸段')) }}</td>
                <td>{{ formatAnswer(getQuestionByTitle('2.2 腰胸段-躯干旋转角度（ATR）')) }}</td>
              </tr>
              <tr>
                <td>腰段</td>
                <td>{{ formatAnswer(getQuestionByTitle('2.3 腰段')) }}</td>
                <td>{{ formatAnswer(getQuestionByTitle('2.3 腰段-躯干旋转角度（ATR）')) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 脊柱运动试验部分 -->
      <div class="report-section">
        <h2 class="section-title">二、脊柱运动试验</h2>
        <div class="table-container">
          <table class="assessment-table">
            <thead>
              <tr>
                <th>检查项目</th>
                <th>结果</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in getQuestionsBySection('脊柱运动试验')" :key="'motion-'+index">
                <td class="table-label">{{ formatTitle(item.title) }}</td>
                <td class="table-value">{{ formatAnswer(item) }}</td>
              </tr>
              <!-- 第二次躯干旋转测量仪检查 -->
              <tr>
                <td colspan="2" class="subsection-title">6. 第二次 躯干旋转测量仪检查</td>
              </tr>
              <tr>
                <td>① 胸段ATR</td>
                <td>{{ formatAnswer(getQuestionByTitle('① 胸段ATR')) }}</td>
              </tr>
              <tr>
                <td>② 腰胸段ATR</td>
                <td>{{ formatAnswer(getQuestionByTitle('② 腰胸段ATR')) }}</td>
              </tr>
              <tr>
                <td>③ 腰段ATR</td>
                <td>{{ formatAnswer(getQuestionByTitle('③ 腰段ATR')) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 长短腿检查部分 -->
      <div class="report-section">
        <h2 class="section-title">三、长短腿检查</h2>
        <div class="table-container">
          <table class="assessment-table">
            <tbody>
              <tr>
                <td class="table-label">是否有长短腿</td>
                <td class="table-value">{{ formatAnswer(getQuestionByTitle('1. 是否有长短腿')) }}</td>
              </tr>
              <tr>
                <td class="table-label">左腿长度</td>
                <td class="table-value">{{ getQuestionByTitle('- 左 腿长度( CM ) :').answer }} cm</td>
              </tr>
              <tr>
                <td class="table-label">右腿长度</td>
                <td class="table-value">{{ getQuestionByTitle('- 右 腿长度( CM ) :').answer }} cm</td>
              </tr>
              <tr>
                <td class="table-label">两腿长度之差</td>
                <td class="table-value">{{ legDifference }} cm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 足弓检查部分 -->
      <div class="report-section">
        <h2 class="section-title">四、足弓检查</h2>
        <div class="table-container">
          <table class="assessment-table">
            <thead>
              <tr>
                <th>检查部位</th>
                <th>结果</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>左足</td>
                <td>
                  {{ formatAnswer(getQuestionByTitle('1. 左足')) }}
                  <span v-if="getQuestionByTitle('左足-其他 :').answer">
                    ({{ getQuestionByTitle('左足-其他 :').answer }})
                  </span>
                </td>
              </tr>
              <tr>
                <td>右足</td>
                <td>
                  {{ formatAnswer(getQuestionByTitle('1. 右足')) }}
                  <span v-if="getQuestionByTitle('右足-其他 :').answer">
                    ({{ getQuestionByTitle('右足-其他 :').answer }})
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 站立位X片部分 -->
      <div class="report-section">
        <h2 class="section-title">五、站立位X片</h2>
        <div class="table-container">
          <table class="assessment-table">
            <tbody>
              <tr>
                <td class="table-label">主弯Cobb角</td>
                <td class="table-value">{{ getQuestionByTitle('1. 主弯Cobb角( ° ):').answer || '/' }}°</td>
              </tr>
              <tr>
                <td class="table-label">顶椎</td>
                <td class="table-value">{{ formatAnswer(getQuestionByTitle('顶椎')) }}</td>
              </tr>
              <tr>
                <td class="table-label">次弯Cobb角</td>
                <td class="table-value">{{ getQuestionByTitle('2. 次弯Cobb角( ° ):').answer || '/' }}°</td>
              </tr>
              <tr>
                <td class="table-label">顶椎</td>
                <td class="table-value">{{ formatAnswer(getQuestionByTitle('顶椎')) }}</td>
              </tr>
              <tr>
                <td class="table-label">Risser征</td>
                <td class="table-value">{{ formatAnswer(getQuestionByTitle('3. Risser征')) }}</td>
              </tr>
              <tr>
                <td class="table-label">生理曲度</td>
                <td class="table-value">{{ formatAnswer(getQuestionByTitle('4. 生理曲度')) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 脊柱前后弯曲异常检查部分 -->
      <div class="report-section">
        <h2 class="section-title">六、脊柱前后弯曲异常检查</h2>
        <div class="table-container">
          <table class="assessment-table">
            <tbody>
              <tr v-for="(item, index) in getQuestionsBySection('脊柱前后弯曲异常检查')" :key="'curve-'+index">
                <td class="table-label">{{ formatTitle(item.title) }}</td>
                <td class="table-value">{{ formatAnswer(item) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 进行因子部分 -->
      <div class="report-section">
        <h2 class="section-title">七、进行因子（风险系数）</h2>
        <div class="table-container">
          <table class="assessment-table">
            <tbody>
              <tr>
                <td class="table-label">进行因子（风险系数）</td>
                <td class="table-value">{{ getQuestionByTitle('进行因子（风险系数）:').answer || '/' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 既往病史部分 -->
      <div class="report-section">
        <h2 class="section-title">八、既往病史</h2>
        <div class="table-container">
          <table class="assessment-table">
            <tbody>
              <tr>
                <td class="table-label">既往病史</td>
                <td class="table-value">{{ formatAnswer(getQuestionByTitle('既往病史')) }}</td>
              </tr>
              <tr v-if="getQuestionByTitle('其他特殊情况:').answer">
                <td class="table-label">其他特殊情况</td>
                <td class="table-value">{{ getQuestionByTitle('其他特殊情况:').answer }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 筛查结果部分 -->
      <div class="report-section result-section">
        <h2 class="section-title">筛查结果</h2>
        <div class="table-container">
          <table class="assessment-table">
            <tbody>
              <tr>
                <td class="table-label">筛查结果</td>
                <td class="table-value highlight">{{ formatAnswer(getQuestionByTitle('筛查结果')) }}</td>
              </tr>
              <tr v-if="hasSpineScoliosis">
                <td class="table-label">脊柱侧弯类别</td>
                <td class="table-value highlight">{{ formatAnswer(getQuestionByTitle('脊柱侧弯类别')) }}</td>
              </tr>
              <tr>
                <td class="table-label">建议</td>
                <td class="table-value">{{ getQuestionByTitle('建议:').answer || '无' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- 操作按钮区域 -->
    <div class="action-buttons">
      <van-button type="primary" icon="printer" @click="printReport">打印报告</van-button>
      <van-button type="info" icon="down" @click="exportReport">导出PDF</van-button>
      <van-button type="default" icon="arrow-left" @click="goBack">返回</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpineAssessmentReport',
  props: {
    // 可以直接接收问卷数据
    assessmentData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      assessmentDate: '',
      patientInfo: {
        name: '',
        age: '',
        gender: ''
      },
      questionInfos: [],
      legDifference: 0
    }
  },
  computed: {
    // 判断是否有脊柱侧弯
    hasSpineScoliosis () {
      const result = this.getQuestionByTitle('筛查结果')
      return result && result.answer === '3'
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      this.assessmentDate = this.$route.query.assessmentDate
      // 如果有直接传入的数据，优先使用
      if (this.assessmentData && this.assessmentData.length > 0) {
        this.questionInfos = this.assessmentData
      } else {
        // 从路由参数获取数据
        const assessmentDataString = this.$route.params.assessmentData || this.$route.query.assessmentData
        if (assessmentDataString) {
          try {
            this.questionInfos = JSON.parse(assessmentDataString)
          } catch (error) {
            console.error('解析评估数据失败', error)
          }
        }
      }

      // 计算两腿长度差
      this.calculateLegDifference()
    },

    // 计算两腿长度差
    calculateLegDifference () {
      const leftLeg = this.getQuestionByTitle('- 左 腿长度( CM ) :')
      const rightLeg = this.getQuestionByTitle('- 右 腿长度( CM ) :')

      if (leftLeg && leftLeg.answer && rightLeg && rightLeg.answer) {
        const leftValue = parseFloat(leftLeg.answer)
        const rightValue = parseFloat(rightLeg.answer)
        this.legDifference = Math.abs(leftValue - rightValue).toFixed(1)
      }
    },

    // 根据标题查找问题
    getQuestionByTitle (title) {
      return this.questionInfos.find(item => item.title === title) || {}
    },

    // 根据章节获取问题列表
    getQuestionsBySection (sectionName) {
      // 基于章节名找到对应的问题
      // 这里需要根据具体的数据结构调整逻辑
      return this.questionInfos.filter(item => {
        // 根据您的数据结构实现筛选逻辑
        if (sectionName === '基本信息') {
          return item.title && item.title.includes('月经初潮')
        } else if (sectionName === '脊柱运动试验') {
          return item.title && (
            item.title.includes('脊柱前屈活动范围') ||
            item.title.includes('脊柱背伸活动范围') ||
            item.title.includes('脊柱左侧弯活动范围') ||
            item.title.includes('脊柱右侧弯范围') ||
            item.title.includes('脊柱左右扭转运动')
          )
        } else if (sectionName === '脊柱前后弯曲异常检查') {
          return item.title && (
            item.title === '1. 一般检查 ' ||
            item.title === '2. 俯卧试验 ' ||
            item.title === '3. 脊柱前后弯曲类型 ' ||
            item.title === '3.1 脊柱后凸程度'
          )
        }
        return false
      })
    },

    // 根据标题开头获取问题
    getQuestionsByTitle (titleStart) {
      return this.questionInfos.filter(item =>
        item.title && item.title.includes(titleStart)
      )
    },

    // 格式化标题
    formatTitle (title) {
      if (!title) return ''

      // 去除标题开头的序号和符号
      return title.replace(/^[\d.①②③\- ]+/, '').trim()
    },

    // 格式化答案
    formatAnswer (item) {
      if (!item || !item.answer) return '/'

      if (item.qType === 'radio') {
        // 查找匹配的选项标签
        const option = (item.radioOpts || []).find(opt => opt.value === item.answer)
        return option ? option.label.replace(/^[A-Z0-9]、/, '') : item.answer
      } else if (item.qType === 'checkbox') {
        // 处理多选
        if (Array.isArray(item.answer)) {
          return item.answer.map(ans => {
            const option = (item.checkboxOpts || []).find(opt => opt.value === ans)
            return option ? option.label.replace(/^[0-9]、/, '') : ans
          }).join('、')
        }
      }

      return item.answer
    },

    // 格式化日期
    formatDate (date) {
      if (!date) return ''
      const d = new Date(date)
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')} ${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}:${String(d.getSeconds()).padStart(2, '0')}`
    },

    // 打印报告
    printReport () {
      window.print()
    },

    // 导出PDF
    exportReport () {
      // 这里需要集成PDF导出功能
      // 可以使用html2canvas + jspdf等库实现
      alert('PDF导出功能待实现')
    },

    // 返回上一页
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.assessment-report {
  padding: 20px;
  background-color: #fff;
  max-width: 900px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.report-header {
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 2px solid #0da65e;
  padding-bottom: 15px;
}

.report-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #0da65e;
}

.report-meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.meta-item {
  margin: 5px 15px;
}

.meta-label {
  font-weight: bold;
}

.report-section {
  margin-bottom: 25px;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
  color: #0a825c;
}

.subsection-title {
  font-size: 16px;
  margin: 10px 0;
  color: #333;
}

.table-container {
  overflow-x: auto;
}

.assessment-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
}

.assessment-table th,
.assessment-table td {
  border: 1px solid #ddd;
  padding: 8px 12px;
  text-align: left;
}

.assessment-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.table-label {
  font-weight: bold;
  width: 30%;
}

.result-section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
}

.highlight {
  color: #e74c3c;
  font-weight: bold;
}

.action-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* 打印样式 */
@media print {
  .action-buttons {
    display: none;
  }

  .assessment-report {
    padding: 0;
  }

  /* 分页控制 */
  .report-section {
    page-break-inside: avoid;
  }
}
</style>
