<template>
  <div>
    <NavBar v-if="!isApp" :title="title" left-arrow/>
    <PageMarginTop/>
    <div v-if="loading" style="text-align: center;padding-top: 1px">
      <van-loading color="#0da65e" style="margin-top: 32px" type="spinner"/>
      <div style="text-align: center;margin-top: 16px;color: #727272">加载中...</div>
    </div>
    <div v-else>
      <div v-if="!isApp" style="height: 48px"></div>
      <Questionnaire v-for="(item,index) in questionInfos" :key="index" :info="getQuestionInfo(index)"
                     @onInputChange="onInputChange">
        <div style="font-size: 20px;font-weight: bold;margin-left: 16px;">{{ `两腿长度之差: ${legDifference}cm` }}</div>
        <van-divider/>
      </Questionnaire>

      <!-- 添加查看报告按钮 -->
      <div class="action-buttons" v-if="isShowReportBtn">
        <van-button type="primary" size="large" @click="viewReport" :disabled="loading">查看评估报告</van-button>
      </div>
    </div>
    <div style="height: 200px;width: 100%"></div>

    <!--    <button @click="test" style="margin-bottom: 10px;width: 100%;padding-bottom: 24px">TTTT</button>-->

  </div>
</template>

<script>
/* eslint-disable dot-notation */
import NavBar from '../components/NavBar'
import PageMarginTop from '../components/PageMarginTop'
import Questionnaire from './Questionnaire'
import getQuestionInfoDatas from './QuestionnaireSpineData'

export default {
  name: 'AssessmentSpine',
  components: {
    Questionnaire,
    PageMarginTop,
    NavBar
  },
  data () {
    return {
      loading: true,
      title: '脊柱康复评估',
      assessmentFormTitle: '脊柱侧弯评估表(AIS-SLS)',
      isApp: false,
      from: '',
      questionInfos: null,
      legDifference: 0,
      legLeft: 0,
      legRight: 0,
      assessmentDate: '',
      isShowReportBtn: false
    }
  },
  computed: {},
  mounted () {
    this.from = this.$route.query.from
    console.log('from', this.from)
    this.isApp = this.$store.state.isApp

    if (this.from && this.from === 'ScreeningTableListPage') {
      // 暂时没用
      this.isApp = true
      this.$store.commit('setIsApp', this.$route.query)
    } else if (this.from && this.from === 'PatientWeb') {
      console.log('进入', 'PatientWeb')
      this.isApp = false
      this.$store.commit('setIsApp', false)
      console.log('this.$store.state.isApp', this.$store.state.isApp)
      this.showAssessmentResult(this.$store.state.assessment.jsonString)
      this.assessmentDate = this.$route.query.assessmentDate
      if (!this.isShowReportBtn) {
        this.viewReport()
      }
    } else {
      this.checkApp()
      this.reportBridgeSuccess()
    }

    this.initFormOptions()
  },
  methods: {
    test () {
      console.log(this.questionInfos)
    },
    onInputChange (questionInfo) {
      console.log('onInputChange', questionInfo)
      if (questionInfo.title === '- 左 腿长度( CM ) :') {
        this.legLeft = parseFloat(questionInfo.answer)
      }
      if (questionInfo.title === '- 右 腿长度( CM ) :') {
        this.legRight = parseFloat(questionInfo.answer)
      }
      this.legDifference = Math.abs(this.legRight - this.legLeft)
    },
    getQuestionInfo (index) {
      return this.questionInfos[index]
    },
    initFormOptions () {
      const query = this.$route.query
      if (query?.isReadAssess) {
      } else {
        // 录入模式
        this.questionInfos = getQuestionInfoDatas()
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }
    },
    checkApp () {
      const query = this.$route.query
      if (query?.isApp) {
        this.$store.commit('setIsApp', query.isApp)
        this.isApp = query.isApp
      }
      if (this.isApp) {
        window.getAssessmentData = this.getAssessmentData
        window.setAssessmentData = this.setAssessmentData
      }
    },
    reportBridgeSuccess () {
      if (!this.isApp) {
        return
      }
      if (window.android && window.android.onBridgeSuccess) {
        var fromAndroid = window.android.onBridgeSuccess('脊柱康复评估')
        console.log(fromAndroid)
        if (this.$route.query?.isReadAssess) {
          // 展示模式
          if (!this.isApp) {
            return
          }
          if (window.android && window.android.getAssessmentJson) {
            const assessmentJsonString = window.android.getAssessmentJson()
            this.showAssessmentResult(assessmentJsonString)
          } else {
            alert('桥接失败')
          }
        }
      } else {
        alert(typeof (window.android))
        this.title = '桥接失败'
      }
    },
    onSubmit () {

    },
    showAssessmentResult (assessmentJsonString) {
      this.questionInfos = JSON.parse(assessmentJsonString)
      console.log(this.questionInfos)
      this.loading = false
    },
    setAssessmentData (data) {
      // dialog 展示data信息
      // this.fromapp = '调用了setAssessmentData'
      // this.fromapp = data
      if (JSON.stringify(data).length > 10) {
        this.questionInfos = data
      }
    },
    getAssessmentData () {
      // 回调给Android
      const restul = JSON.stringify(this.questionInfos)
      // alert(restul)
      return restul
    },
    // 新增方法：查看报告
    viewReport () {
      // 将问卷数据转为JSON字符串，通过URL参数传递
      const assessmentData = JSON.stringify(this.questionInfos)
      this.$router.replace({
        name: 'SpineAssessmentReport',
        query: {
          assessmentData: assessmentData,
          assessmentDate: this.assessmentDate
        }
      })
    }
    // onMounted () {
    //   window.android2.onMounted('onMounted')
    // }
  }

}
</script>

<style scoped>

.title0 {
  font-size: 24px;
  font-weight: bold;
  padding: 16px
}

.title1 {
  font-size: 20px;
  font-weight: bold;
  padding: 16px
}

.question {
  padding-left: 16px;
  padding-right: 16px;
  font-weight: bold;
}

.checkbox {
  margin-bottom: 5px;
}

.radio {
  margin-right: 24px;
  margin-bottom: 10px;
}

.img-icon {
  height: 20px;
}

.action-buttons {
  padding: 16px;
  margin-top: 20px;
}
</style>
