import axios from 'axios'
import VueClipboard from 'vue-clipboard2'
import {
  ActionSheet,
  AddressEdit,
  AddressList,
  Area,
  Button,
  Card,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Col,
  ContactCard,
  ContactEdit,
  Dialog,
  Divider,
  DropdownItem,
  DropdownMenu,
  Empty,
  Field,
  Form,
  GoodsAction,
  GoodsActionButton,
  GoodsActionIcon,
  Grid,
  GridItem,
  Icon,
  Image as VanImage,
  List,
  Loading,
  NavBar,
  Panel,
  Picker,
  Popover,
  Popup,
  PullRefresh,
  Radio,
  RadioGroup,
  Row,
  Search,
  Skeleton,
  Stepper,
  SubmitBar,
  Swipe,
  SwipeItem,
  Switch,
  Tab,
  Tabbar,
  TabbarItem,
  Tabs,
  Tag,
  Toast,
  Uploader
} from 'vant'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BASEURL } from './network/Apis'
import VideoPlayer from 'vue-video-player'
import { VueJsonp } from 'vue-jsonp'
import * as DEV from '@/h5/doucan/DEV'

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

Vue.use(VueClipboard)
Vue.use(VueJsonp)

Vue
  .use(NavBar)
  .use(Loading)
  .use(List)
  .use(Cell)
  .use(CellGroup)
  .use(Skeleton)
  .use(Dialog)
  .use(Tab)
  .use(Tabs)
  .use(Area)
  .use(ActionSheet)
  .use(Empty)
  .use(PullRefresh)
  .use(Card)
  .use(Row)
  .use(Col)
  .use(VanImage)
  .use(Icon)
  .use(Form)
  .use(Field)
  .use(Button)
  .use(Popover)
  .use(Picker)
  .use(Popup)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(RadioGroup)
  .use(Radio)
  .use(Stepper)
  .use(Divider)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Grid)
  .use(GridItem)
  .use(AddressList)
  .use(Tag)
  .use(Toast)
  .use(VideoPlayer)
  .use(Swipe)
  .use(SwipeItem)
  .use(Uploader)
  .use(Search)
  .use(GoodsAction)
  .use(GoodsActionButton)
  .use(GoodsActionIcon)
  .use(ContactCard)
  .use(ContactEdit)
  .use(AddressEdit)
  .use(SubmitBar)
  .use(Switch)
  .use(Panel)
  .use(Search)

Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog
Vue.prototype.$alert = Dialog.alert
Vue.prototype.$confirm = Dialog.confirm

axios.defaults.baseURL = BASEURL
axios.interceptors.request.use(config => {
  if (!config.headers.token) {
    // TODO 对接
    config.headers.token = store.state.user.profile.token
  }
  if (!config.headers.token) {
    config.headers.token = '795287af-2e9b-49a5-86eb-48f8be6d54bd' // 技术测试
  }

  return config
})
// axios.interceptors.request.use(config => {
//   console.log(config)
//   config.headers.Authorization = window.sessionStorage.getItem('token')
//   return config
// })
Vue.prototype.$http = axios
Vue.config.productionTip = false

// 关闭日志输出
if (!DEV.DEBUG) {
  console.log = function () {
  }
}

router.beforeEach((to, from, next) => {
  store.commit('route/setFrom', from.path)
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
