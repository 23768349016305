<template>
  <div class="health-station-container">
    <!--    <div v-if="!isLogin">-->
    <!--      <div-->
    <!--        style="height: 56px;width: 100%;background-color: #0fb08d;text-align: center;line-height: 56px;font-size:24px;color: white;"-->
    <!--        @click="login">-->
    <!--        点击注册/登录-->
    <!--      </div>-->
    <!--    </div>-->
    <router-view />
    <van-tabbar v-model="active" active-color="#E66C34" inactive-color="#000" route @change="onChange">
      <van-tabbar-item icon="home-o" replace to="/healthstation/home">首页
      </van-tabbar-item>
      <van-tabbar-item v-if="isWxMiniProgram" icon="chat-o" @click="onConsultClick">咨询
      </van-tabbar-item>
      <van-tabbar-item icon="contact-o" replace to="/healthstation/person">我的
      </van-tabbar-item>
      <!--      <van-tabbar-item name="person" icon="friends-o" replace to="/home/person">个人中心</van-tabbar-item>-->
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      active: 'home',
      isWxMiniProgram: false
    }
  },
  computed: {
    isLogin () {
      return this.$store.state.user.profile.isLogin
    }
  },
  mounted () {
    this.isWxMiniProgram = this.isWxMiniCodeWebviewEnv()
    console.log('是否在小程序环境', this.isWxMiniProgram)
    console.log('登录用户', this.$store.state.user)
    // this.initIM()
  },
  methods: {
    isWxMiniCodeWebviewEnv () {
      console.log('window.wx', window.wx.miniProgram)

      try {
        return (
          typeof window !== 'undefined' &&
          typeof window.wx !== 'undefined' &&
          typeof window.wx.miniProgram !== 'undefined'
        )
      } catch (error) {
        console.warn('检查微信小程序环境时发生错误:', error)
        return false
      }
    },
    onConsultClick () {
      if (this.isWxMiniProgram) {
        // 从store获取用户信息
        const userProfile = this.$store.state.user.profile
        const phone = encodeURIComponent(userProfile.phone || '')
        const realname = encodeURIComponent(userProfile.name || '')

        window.wx.miniProgram.navigateTo({
          url: `/pages/consult_detail/consult_detail?phone=${phone}&name=${realname}`
        })
      }
    },
    login () {
      this.$router.push('/login/index')
    },
    initIM () {
    },
    onChange (val) {
      // this.$toast(val)
    }
  }
}
</script>

<style scoped>
/* 确保主容器占满整个视口高度 */
.health-station-container {
  min-height: 100vh;
  padding-bottom: 50px;
  /* 为底部 tabbar 预留空间 */
}

/* 固定 tabbar 在底部 */
:deep(.van-tabbar) {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
</style>
