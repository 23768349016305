<template>
  <div>
    <base-title-bar-scroll-page ref="container" :showTitleBar="!isApp" title="患者信息笔记" @scroll="handleScroll">
      <div>
        <!-- 医生信息区域 -->
        <div v-if="doctorInfo.realname" class="doctor-info">
          <van-image :src="doctorInfo.avatar" height="60" round width="60" />
          <div>
            <div class="doctor-name">当前医生信息：{{ doctorInfo.realname }}</div>
            <div class="doctor-hospital">{{ doctorInfo.hospital || '暂无医院信息' }}</div>
          </div>
        </div>
        <!-- 用户信息区域 -->
        <div :class="{ 'no-title': isApp }" class="user-info">
          <!-- 加载状态 -->
          <div v-if="isLoadingInfo" class="loading-wrapper">
            <van-loading color="#2c8be9" type="spinner" />
            <span class="loading-text">加载中...</span>
          </div>

          <template v-else>
            <!-- 信息标签页 -->
            <van-tabs v-model="activeTab" animated sticky swipeable>
              <!-- 基本信息标签页 -->
              <van-tab name="basic" title="基本信息">
                <div class="info-section">
                  <div class="info-header">
                    <span class="section-title">基本信息</span>
                    <div class="header-right">
                      <span v-if="isDemo" class="demo-tag">演示数据</span>
                      <van-button icon="edit" plain size="small" type="primary" @click="handleEdit('basic')">编辑
                      </van-button>
                    </div>
                  </div>

                  <div class="info-content">
                    <!-- 始终显示的基本信息 -->
                    <div class="basic-info">
                      <div class="info-item">
                        <span class="info-label">姓名：</span>
                        <span class="info-value">{{ patientInfo.name || '暂无' }}</span>
                      </div>
                      <div class="info-item">
                        <span class="info-label">性别：</span>
                        <span class="info-value">{{ patientInfo.gender || '暂无' }}</span>
                      </div>
                      <div class="info-item">
                        <span class="info-label">年龄：</span>
                        <span class="info-value">{{ patientInfo.age ? `${patientInfo.age}岁` : '暂无' }}</span>
                      </div>
                    </div>

                    <!-- 折叠面板 -->
                    <van-collapse v-model="activeCollapse">
                      <van-collapse-item name="more" title="更多信息">
                        <div class="info-item">
                          <span class="info-label">联系电话：</span>
                          <span class="info-value">{{ patientInfo.phone || '暂无' }}</span>
                        </div>
                        <div class="info-item">
                          <span class="info-label">病历号：</span>
                          <span class="info-value">{{ patientInfo.patientId || '暂无' }}</span>
                        </div>
                        <div class="info-item">
                          <span class="info-label">血型：</span>
                          <span class="info-value">{{ patientInfo.bloodType || '暂无' }}</span>
                        </div>
                        <div class="info-item">
                          <span class="info-label">医保类型：</span>
                          <span class="info-value">{{ patientInfo.insuranceType || '暂无' }}</span>
                        </div>
                        <div class="info-item">
                          <span class="info-label">建档时间：</span>
                          <span class="info-value">{{ formatDateTime(patientInfo.createTime) || '暂无' }}</span>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                  </div>
                </div>
              </van-tab>

              <!-- 诊断信息标签页 -->
              <van-tab name="diagnosis" title="诊断信息">
                <div class="info-section">
                  <div class="info-header">
                    <span class="section-title">主要诊断</span>
                  </div>

                  <div class="info-content">
                    <div v-if="patientInfo.diagnosis && patientInfo.diagnosis.length" class="diagnosis-list">
                      <div v-for="(diag, index) in patientInfo.diagnosis" :key="index" class="diagnosis-item">
                        {{ diag }}
                      </div>
                    </div>
                    <div v-else class="empty-state">
                      暂无诊断信息
                    </div>
                  </div>

                  <div class="info-header">
                    <span class="section-title">既往病史</span>
                  </div>

                  <div class="info-content">
                    <div v-if="patientInfo.medicalHistory && patientInfo.medicalHistory.length" class="history-list">
                      <div v-for="(history, index) in patientInfo.medicalHistory" :key="index" class="history-item">
                        {{ history }}
                      </div>
                    </div>
                    <div v-else class="empty-state">
                      暂无既往病史记录
                    </div>
                  </div>

                  <div class="info-header">
                    <span class="section-title">过敏史</span>
                  </div>

                  <div class="info-content">
                    <div v-if="patientInfo.allergyHistory && patientInfo.allergyHistory.length" class="allergy-list">
                      <div v-for="(allergy, index) in patientInfo.allergyHistory" :key="index" class="allergy-item">
                        {{ allergy }}
                      </div>
                    </div>
                    <div v-else class="empty-state">
                      暂无过敏史记录
                    </div>
                  </div>
                </div>
              </van-tab>

              <!-- 就诊记录标签页 -->
              <van-tab name="visits" title="就诊记录">
                <div class="info-section">
                  <div class="info-header">
                    <span class="section-title">就诊记录</span>
                  </div>

                  <div class="info-content">
                    <div v-if="patientInfo.visitHistory && patientInfo.visitHistory.length" class="visit-list">
                      <div v-for="(visit, index) in patientInfo.visitHistory" :key="index" class="visit-item">
                        <div class="visit-date">{{ visit.date }}</div>
                        <div class="visit-dept">{{ visit.department }}</div>
                        <div class="visit-doctor">{{ visit.doctor }}</div>
                        <div class="visit-diagnosis">{{ visit.diagnosis }}</div>
                      </div>
                    </div>
                    <div v-else class="empty-state">
                      暂无就诊记录
                    </div>
                  </div>
                </div>
              </van-tab>
            </van-tabs>
          </template>
        </div>

        <!-- 编辑弹窗 -->
        <!-- <van-dialog v-model="showEditDialog" title="编辑患者信息" :show-confirm-button="true" :show-cancel-button="true"
          confirm-button-text="确定" cancel-button-text="取消" :before-close="handleDialogClose"
          :get-container="getContainer" @confirm="handleEditConfirm" @cancel="handleDialogCancel">
          <div class="edit-form">
            <van-field v-model="editForm.name" label="姓名" placeholder="请输入姓名" />
            <van-field v-model="editForm.phone" label="联系电话" placeholder="请输入联系电话" />
            <van-field v-model="editForm.address" label="地址" placeholder="请输入地址" />
          </div>
        </van-dialog> -->

        <!-- 其他内容保持不变 -->
        <div class="action-buttons">
          <div class="action-button" @click="handleAddNote">
            <div class="icon">
              <van-icon color="#2c8be9" name="plus" size="24" />
            </div>
            <span class="text">添加记录</span>
          </div>
          <div class="action-button" @click="handleFilter">
            <div class="icon">
              <van-icon color="#2c8be9" name="filter-o" size="24" />
            </div>
            <span class="text">筛选</span>
          </div>
          <div class="action-button" @click="handleExport">
            <div class="icon">
              <van-icon color="#2c8be9" name="down" size="24" />
            </div>
            <span class="text">导出</span>
          </div>
          <div class="action-button" @click="handleStats">
            <div class="icon">
              <van-icon color="#2c8be9" name="chart-trending-o" size="24" />
            </div>
            <span class="text">统计</span>
          </div>
        </div>

        <div class="note-list">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多数据了" loading-text="加载中..."
            @load="loadMore">
            <patient-note-item v-for="(note, index) in noteList" :key="index" :note="note"
              :show-date-divider="shouldShowDateDivider(note, index)" :is-demo-note="isDemoNote(note)"
              @media-click="handleMediaClick" />
          </van-list>
        </div>
      </div>
    </base-title-bar-scroll-page>

    <!-- 视频播放器 -->
    <div v-if="showVideoPlayer" class="video-player-container" @click="handleContainerClick">
      <div class="video-close" @click="handleVideoClose">
        <i class="video-close-icon"></i>
      </div>
      <video-player ref="videoPlayer" :options="playerOptions" :playsinline="true" class="video-player vjs-custom-skin"
        @pause="handleVideoClose" @play="onPlayerPlay" @click.stop />
    </div>
  </div>
</template>

<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { Button, Collapse, CollapseItem, Icon, ImagePreview, List, Loading, Tab, Tabs } from 'vant'
import 'vant/lib/dialog/style'
import VideoPlayer from '@/common-components/VideoPlayer/VideoPlayer.vue'
import 'video.js/dist/video-js.css'
import { mapGetters } from 'vuex'
import PatientNoteDao from '@/h5/doucan/network/PatientNoteDao'
import UserDao from '@/h5/doucan/network/UserDao'
import DoctorUserDao from '@/h5/doucan/network/DoctorUserDao'
import PatientNoteItem from '@/h5/doucan/components/PatientNoteItem.vue'

export default {
  name: 'patient_manager_info_note',
  components: {
    BaseTitleBarScrollPage,
    VideoPlayer,
    PatientNoteItem,
    'van-list': List,
    'van-icon': Icon,
    'van-tab': Tab,
    'van-tabs': Tabs,
    // 'van-dialog': Dialog,
    // 'van-field': Field,
    'van-loading': Loading,
    'van-button': Button,
    'van-collapse': Collapse,
    'van-collapse-item': CollapseItem
  },
  data () {
    return {
      patientUserId: '',
      doctorUserId: '',
      noteList: [],
      isApp: false,
      showVideoPlayer: false,
      currentVideoUrl: '',
      currentVideoIndex: 0,
      currentAttachments: [],
      // 分页相关数据
      pageSize: 10,
      currentPage: 1,
      total: 0,
      loading: false,
      finished: false,
      isLoadingInfo: false, // 添加信息加载状态
      showEditDialog: false, // 编辑弹窗显示状态
      editForm: {}, // 编辑表单数据
      patientInfo: {
        name: '',
        age: '',
        gender: '',
        diagnosis: [],
        // 新增字段
        patientId: '', // 病历号
        phone: '', // 联系电话
        address: '', // 地址
        emergencyContact: '', // 紧急联系人
        emergencyPhone: '', // 紧急联系电话
        medicalHistory: [], // 既往病史
        allergyHistory: [], // 过敏史
        visitHistory: [], // 就诊历史
        createTime: '', // 建档时间
        lastVisitTime: '', // 最近就诊时间
        insuranceType: '', // 医保类型
        bloodType: '' // 血型
      },
      activeTab: 'basic', // 当前激活的信息标签页
      playerOptions: {
        autoplay: false,
        muted: false,
        loop: false,
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        poster: '',
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true
        }
      },
      doctorInfo: {
        name: '',
        hospitalName: ''
        // 其他医生信息字段
      },
      activeCollapse: [],
      isDemo: false
    }
  },
  created () {

  },
  async mounted () {
    // 获取URL参数
    this.patientUserId = this.$route.query.patient_user_id
    this.doctorUserId = this.$route.query.doctor_user_id
    this.isApp = this.$route.query.app === 'true'
    console.log('患者ID:', this.patientUserId)
    console.log('医生ID:', this.doctorUserId)

    // 获取患者信息
    this.fetchPatientInfo()
    // 初始化数据
    this.fetchNoteList()

    // 获取token参数 到 store.state.user.profile.token
    const token = this.$route.query.token
    console.log('token:', token)
    // 设置store.state.user.profile.token
    // this.$store.state.user.profile.token = token
    this.$store.commit('user/setToken', token)
    // 获取医生信息
    await this.fetchDoctorInfo()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    /**
     * 处理媒体点击
     * @param {Object} item 当前点击的媒体项
     * @param {Array} attachments 当前记录的所有附件
     * @param {Number} index 当前点击的索引
     */
    handleMediaClick ({ item, attachments, index }) {
      if (item.type === 'video') {
        this.showVideo(item, index)
      } else {
        this.showImagePreview(attachments, index)
      }
    },

    /**
     * 从视频获取第一帧作为封面
     * @param {string} videoUrl 视频URL
     * @returns {Promise<string>} 返回第一帧的 base64 数据
     */
    getVideoFirstFrame (videoUrl) {
      return new Promise((resolve, reject) => {
        console.log('开始获取视频封面:', videoUrl)
        const video = document.createElement('video')
        video.crossOrigin = 'anonymous'
        video.src = videoUrl
        video.preload = 'auto'

        // 设置超时
        const timeout = setTimeout(() => {
          console.warn('获取视频封面超时:', videoUrl)
          video.remove()
          reject(new Error('获取视频封面超时'))
        }, 15000) // 增加到15秒

        // 视频加载失败时的处理
        video.onerror = (e) => {
          console.warn('视频加载失败:', videoUrl, e.target.error)
          clearTimeout(timeout)
          video.remove()
          reject(new Error(`视频加载失败: ${e.target.error?.message || '未知错误'}`))
        }

        // 元数据加载完成后设置时间
        video.onloadedmetadata = () => {
          console.log('视频元数据加载完成:', videoUrl, '时长:', video.duration)
          video.currentTime = 0.1
        }

        // 视频可以播放时的处理
        video.oncanplay = () => {
          console.log('视频可以播放:', videoUrl)
        }

        // 时间设置完成后的事件
        video.onseeked = () => {
          console.log('视频seek完成,准备获取封面:', videoUrl)
          try {
            const canvas = document.createElement('canvas')
            canvas.width = video.videoWidth
            canvas.height = video.videoHeight
            console.log('视频尺寸:', canvas.width, 'x', canvas.height)

            const ctx = canvas.getContext('2d')
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
            const dataUrl = canvas.toDataURL('image/jpeg')
            console.log('成功获取视频封面:', videoUrl)

            clearTimeout(timeout)
            video.remove()
            resolve(dataUrl)
          } catch (e) {
            console.warn('获取视频封面失败:', videoUrl, e)
            clearTimeout(timeout)
            video.remove()
            reject(e)
          }
        }

        // 视频加载进度
        video.onprogress = (e) => {
          if (video.buffered.length > 0) {
            console.log('视频加载进度:', videoUrl,
              Math.round(video.buffered.end(0) / video.duration * 100) + '%')
          }
        }
      })
    },

    /**
     * 显示视频
     */
    async showVideo (item, index) {
      // 先关闭之前的视频播放器
      if (this.showVideoPlayer && this.player) {
        try {
          this.player.pause()
        } catch (e) {
          console.warn('暂停上一个视频失败:', e)
        }
      }

      try {
        // 尝试获取视频第一帧作为封面
        const poster = await this.getVideoFirstFrame(item.url)

        // 设置播放器
        this.playerOptions = {
          autoplay: false,
          muted: false,
          loop: false,
          preload: 'auto',
          language: 'zh-CN',
          aspectRatio: '16:9',
          fluid: true,
          sources: [{
            type: 'video/mp4',
            src: item.url
          }],
          poster: poster, // 使用获取到的第一帧作为封面
          width: document.documentElement.clientWidth,
          notSupportedMessage: '此视频暂无法播放，请稍后再试',
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true
          }
        }
      } catch (e) {
        console.warn('获取视频封面失败,使用默认封面:', e)
        // 如果获取第一帧失败,使用默认封面
        this.playerOptions = {
          autoplay: false,
          muted: false,
          loop: false,
          preload: 'auto',
          language: 'zh-CN',
          aspectRatio: '16:9',
          fluid: true,
          sources: [{
            type: 'video/mp4',
            src: item.url
          }],
          poster: item.thumbnail || '',
          width: document.documentElement.clientWidth,
          notSupportedMessage: '此视频暂无法播放，请稍后再试',
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true
          }
        }
      }

      this.currentVideoIndex = index
      this.showVideoPlayer = true

      // 等待DOM更新后初始化播放器
      this.$nextTick(() => {
        if (this.player) {
          this.player.src({
            type: 'video/mp4',
            src: item.url
          })
          this.player.load()
        }
      })
    },

    /**
     * 显示图片预览
     */
    showImagePreview (attachments, index) {
      // 收集所有媒体项
      const mediaItems = attachments.map(attachment => {
        if (attachment.type === 'video') {
          return {
            type: 'video',
            url: attachment.thumbnail,
            videoUrl: attachment.url
          }
        }
        return {
          type: 'image',
          url: attachment.url
        }
      })

      // 使用 vant 的 ImagePreview
      const instance = ImagePreview({
        images: mediaItems.map(item => item.url),
        startPosition: index,
        closeable: true,
        onClose: () => {
          console.log('预览关闭')
        },
        onChange: (idx) => {
          const currentItem = mediaItems[idx]
          if (currentItem.type === 'video') {
            this.showVideo({ url: currentItem.videoUrl }, idx)
            instance.close()
          }
        }
      })
    },

    /**
     * 关闭视频播放器
     */
    handleVideoClose () {
      if (this.player) {
        try {
          this.player.pause()
        } catch (e) {
          console.warn('视频暂停失败:', e)
        }
      }
      this.showVideoPlayer = false
      this.currentVideoUrl = ''
    },

    /**
     * 处理视频播放
     */
    onPlayerPlay () {
      // 用户手动点击播放时的处理
      if (this.player) {
        try {
          this.player.play()
        } catch (e) {
          console.warn('视频播放失败:', e)
        }
      }
    },

    /**
     * 处理笔记列表数据,为视频获取封面图
     * @param {Array} notes 笔记列表数据
     */
    async processNoteList (notes) {
      notes = notes.filter(note => note !== null && note !== undefined)
      for (const note of notes) {
        if (note.attachments && note.attachments.length) {
          for (const attachment of note.attachments) {
            if (attachment.type === 'video' && !attachment.thumbnail) {
              try {
                // 获取视频第一帧作为封面
                attachment.thumbnail = await this.getVideoFirstFrame(attachment.url)
              } catch (e) {
                console.warn('获取视频封面失败:', e)
                // 设置一个默认的封面图
                attachment.thumbnail = 'https://cdn.keihong.tech/img/video_default_cover.png'
              }
            }
          }
        }
      }
      return notes
    },

    /**
     * 处理滚动事件
     * @param {Event} event 滚动事件对象
     */
    handleScroll (event) {
      const {
        scrollHeight,
        scrollTop,
        clientHeight
      } = event.target

      // 距离底部30px时加载更多
      if (scrollHeight - scrollTop - clientHeight < 30 && !this.loading && !this.finished) {
        console.log('触发加载更多')
        this.loadMore()
      }
    },

    /**
     * 从服务器获取笔记列表数据
     */
    async fetchNoteList () {
      try {
        // 重置状态
        this.loading = true
        this.finished = false
        this.currentPage = 1
        this.noteList = []

        console.log('初始化加载笔记列表 - 页码:', this.currentPage, '每页数量:', this.pageSize)

        // 构建查询参数
        const params = {
          doctorUserId: this.doctorUserId,
          patientUserId: this.patientUserId,
          page: this.currentPage,
          size: this.pageSize
        }

        // 调用API获取数据
        const response = await PatientNoteDao.getPatientNoteList(params)
        if (!response || !response.content || response.content.length === 0) {
          // 使用 store 中的模拟数据
          const mockNotes = this.getPagedNotes(this.currentPage, this.pageSize)
          this.total = this.getNotesTotal
          this.noteList = await this.processNoteList(mockNotes)
          this.finished = this.noteList.length >= this.total
          return
        }

        console.log('获取到的初始笔记数据:', response)

        // 设置总数
        this.total = response.totalElements || 0

        // 处理笔记列表数据,为视频获取封面图
        const processedNotes = await this.processNoteList(response.content)
        console.log('处理后的初始笔记数据:', processedNotes)

        // 设置数据
        this.noteList = processedNotes

        // 判断是否加载完成
        this.finished = this.noteList.length >= this.total
        console.log('数据加载状态 - 当前数量:', this.noteList.length, '总条数:', this.total, '是否完成:', this.finished)
      } catch (error) {
        console.error('获取笔记列表失败:', error)
        // 发生错误时使用模拟数据
        const mockNotes = this.getPagedNotes(this.currentPage, this.pageSize)
        this.total = this.getNotesTotal
        this.noteList = await this.processNoteList(mockNotes)
        this.finished = this.noteList.length >= this.total
      } finally {
        this.loading = false
        this.hideLoading()
      }
    },

    /**
     * 加载更多数据
     */
    async loadMore () {
      // 如果已经加载完成或正在加载，直接返回
      if (this.finished || this.loading) {
        this.loading = false
        return
      }

      try {
        // 设置加载状态
        this.loading = true
        this.currentPage++

        console.log('加载更多数据 - 当前页码:', this.currentPage, '每页数量:', this.pageSize)

        // 构建查询参数
        const params = {
          hospitalId: this.$store.state.hospitalId,
          patientUserId: this.patientUserId,
          page: this.currentPage,
          size: this.pageSize
        }

        // 调用API获取数据
        const response = await PatientNoteDao.getPatientNoteList(params)
        if (!response || !response.content || response.content.length === 0) {
          // 使用 store 中的模拟数据
          const mockNotes = this.getPagedNotes(this.currentPage, this.pageSize)
          if (mockNotes.length > 0) {
            const processedNotes = await this.processNoteList(mockNotes)
            this.noteList = [...this.noteList, ...processedNotes]
          }
          this.finished = this.noteList.length >= this.total
          return
        }

        console.log('获取到的笔记数据:', response)

        // 处理笔记列表数据,为视频获取封面图
        const processedNotes = await this.processNoteList(response.content)
        console.log('处理后的笔记数据:', processedNotes)

        // 追加数据
        this.noteList = [...this.noteList, ...processedNotes]
        console.log('当前列表总数据:', this.noteList.length)

        // 判断是否加载完成
        this.finished = this.noteList.length >= this.total
        console.log('数据加载状态 - 当前数量:', this.noteList.length, '总条数:', this.total, '是否完成:', this.finished)
      } catch (error) {
        console.error('加载更多数据失败:', error)
        // 发生错误时使用模拟数据
        const mockNotes = this.getPagedNotes(this.currentPage, this.pageSize)
        if (mockNotes.length > 0) {
          const processedNotes = await this.processNoteList(mockNotes)
          this.noteList = [...this.noteList, ...processedNotes]
        }
        this.finished = this.noteList.length >= this.total
      } finally {
        this.loading = false
      }
    },

    /**
     * 获取医生信息
     */
    async fetchDoctorInfo () {
      try {
        const doctorData = await DoctorUserDao.getDoctorUserInfo()
        if (!doctorData) {
          console.error('获取医生信息失败')
          return
        }

        console.log('医生信息:', doctorData)
        this.doctorInfo = doctorData
      } catch (error) {
        console.error('获取医生信息失败:', error)
      }
    },

    /**
     * 处理视频播放器容器的点击
     * @param {Event} event 点击事件对象
     */
    handleContainerClick (event) {
      // 如果点击的是视频播放器容器本身,则关闭视频
      if (event.target.classList.contains('video-player-container')) {
        this.handleVideoClose()
      }
    },
    /**
     * 判断是否需要显示日期分隔符
     * @param {Object} note 当前笔记
     * @param {Number} index 当前索引
     * @returns {Boolean} 是否显示分隔符
     */
    shouldShowDateDivider (note, index) {
      if (index === 0) return true
      const prevDate = this.noteList[index - 1].recordDate
      const prevYear = this.getYear(prevDate)
      const prevMonth = this.getMonth(prevDate)
      const currentYear = this.getYear(note.recordDate)
      const currentMonth = this.getMonth(note.recordDate)
      return prevYear !== currentYear || prevMonth !== currentMonth
    },
    /**
     * 格式化日期时间
     * @param {String} dateStr 日期字符串
     * @returns {String} 格式化后的日期时间
     */
    formatDateTime (dateStr) {
      if (!dateStr) return ''
      const date = new Date(dateStr)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      return `${year}-${month}-${day} ${hours}:${minutes}`
    },
    /**
     * 获取年份
     * @param {String} dateStr 日期字符串
     * @returns {String} 年份
     */
    getYear (dateStr) {
      return new Date(dateStr).getFullYear().toString()
    },
    /**
     * 获取月份
     * @param {String} dateStr 日期字符串
     * @returns {Number} 月份
     */
    getMonth (dateStr) {
      return new Date(dateStr).getMonth() + 1
    },
    /**
     * 处理添加记录
     */
    handleAddNote () {
      // 跳转到添加记录页面
      this.$router.push({
        name: 'AddPatientNote',
        query: {
          app: this.isApp,
          patient_user_id: this.patientUserId,
          doctor_user_id: this.doctorUserId
        }
      })
    },

    /**
     * 处理筛选
     */
    handleFilter () {
      // TODO: 实现筛选功能
      this.$toast('筛选功能开发中...')
    },

    /**
     * 处理导出
     */
    handleExport () {
      // TODO: 实现导出功能
      this.$toast('导出功能开发中...')
    },

    /**
     * 处理统计
     */
    handleStats () {
      // TODO: 实现统计功能
      this.$toast('统计功能开发中...')
    },

    /**
     * 处理编辑按钮点击
     * @param {string} tab 当前标签页
     */
    handleEdit (tab) {
      this.$toast('功能开发中...')

      // 根据不同标签页设置不同的编辑表单
      // switch (tab) {
      //   case 'basic':
      //     this.editForm = {
      //       name: this.patientInfo.name,
      //       phone: this.patientInfo.phone,
      //       address: this.patientInfo.address,
      //       bloodType: this.patientInfo.bloodType,
      //       insuranceType: this.patientInfo.insuranceType
      //     }
      //     break
      //   // 可以添加其他标签页的编辑表单
      // }
      // this.showEditDialog = true
    },

    /**
     * 处理编辑确认
     */
    async handleEditConfirm () {
      try {
        // 显示加载提示
        this.$toast.loading({
          message: '保存中...',
          forbidClick: true
        })

        // 调用 UserDao 保存编辑后的信息
        const updatedData = await UserDao.updateUserInfo(this.patientUserId, this.editForm)
        if (!updatedData) {
          this.$toast.fail('保存失败')
          return
        }

        // 更新本地数据
        this.patientInfo = {
          ...this.patientInfo,
          ...this.editForm
        }

        // 显示成功提示
        this.$toast.success('保存成功')

        // 关闭编辑弹窗
        this.showEditDialog = false
      } catch (error) {
        console.error('保存失败:', error)
        this.$toast.fail('保存失败，请重试')
      }
    },

    /**
     * 获取弹窗挂载的容器
     */
    getContainer () {
      return document.querySelector('.user-info')
    },

    /**
     * 处理弹窗取消
     */
    handleDialogCancel () {
      this.showEditDialog = false
    },

    /**
     * 处理弹窗关闭前的回调
     */
    handleDialogClose (action, done) {
      if (action === 'confirm') {
        // 点击确认按钮时，由 handleEditConfirm 处理
        done()
      } else {
        // 点击取消按钮时，直接关闭
        this.showEditDialog = false
        done()
      }
    },

    /**
     * 判断是否为演示笔记
     * @param {Object} note 笔记数据
     * @returns {Boolean} 是否为演示笔记
     */
    isDemoNote (note) {
      // 只判断笔记内容是否为演示数据
      return note && note.content && note.content.includes('(演示数据)')
    },

    /**
     * 获取患者信息
     */
    async fetchPatientInfo () {
      try {
        this.isLoadingInfo = true

        if (this.patientUserId) {
          // 获取患者信息
          const patientData = await UserDao.getUserInfo(this.patientUserId)
          if (!patientData) {
            this.$toast.fail('获取患者信息失败')
            return
          }

          // 映射数据到 patientInfo
          this.patientInfo = {
            ...this.patientInfo,
            name: patientData.realname,
            age: patientData.age,
            gender: patientData.gender,
            phone: patientData.phone,
            patientId: patientData.userId,
            address: patientData.address,
            bloodType: patientData.bloodType,
            insuranceType: patientData.insuranceType,
            createTime: patientData.createTime,
            // 其他字段保持默认值
            diagnosis: [],
            medicalHistory: [],
            allergyHistory: [],
            visitHistory: [],
            emergencyContact: '',
            emergencyPhone: '',
            lastVisitTime: ''
          }
        }
      } catch (error) {
        console.error('获取患者信息失败:', error)
        this.$toast.fail('获取患者信息失败')
      } finally {
        this.isLoadingInfo = false
      }
    }
  },
  watch: {},
  computed: {
    ...mapGetters('globalData', ['getPagedNotes', 'getNotesTotal']),
    player () {
      return this.$refs.videoPlayer?.player
    }
  }
}
</script>
<style scoped>
.action-buttons {
  display: flex;
  justify-content: space-around;
  padding: 15px;
  background: #fff;
  margin-bottom: 10px;
}

.action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.action-button .icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.action-button .text {
  font-size: 12px;
  color: #666;
}

/* 医生信息区域 */
.doctor-info {
  display: flex;
  align-items: center;
  padding: 15px;
  background: #fff;
  margin-bottom: 10px;
}

.doctor-info .doctor-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
  margin-left: 12px;
}

.doctor-info .doctor-hospital {
  font-size: 14px;
  color: #666;
  margin-left: 12px;
}

/* 用户信息区域 */
.user-info {
  background: #fff;
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
}

.info-section {
  padding: 15px;
}

.info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.section-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.header-right {
  display: flex;
  align-items: center;
}

.demo-tag {
  background: #ff9800;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 8px;
}

.info-content {
  background: #f5f7fa;
  border-radius: 8px;
  padding: 15px;
}

.basic-info {
  margin-bottom: 15px;
}

.info-item {
  display: flex;
  margin-bottom: 10px;
  line-height: 1.5;
}

.info-label {
  color: #666;
  min-width: 80px;
}

.info-value {
  color: #333;
  flex: 1;
}

/* 笔记列表区域 */
.note-list {
  padding: 10px;
}

.note-item {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}

.note-item.is-demo::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 20px 20px 0;
  border-style: solid;
  border-color: #ff9800 #fff;
}

.date-divider {
  margin-left: 16px;
  padding: 24px 0 12px;
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
}

.date-divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background: #eee;
  margin-left: 12px;
}

.date-divider .year {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin-right: 8px;
  position: relative;
  padding-right: 12px;
}

.date-divider .year::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 12px;
  background: #ddd;
}

.date-divider .month {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  background: #f5f7fa;
  padding: 4px 12px;
  border-radius: 20px;
  min-width: 64px;
  text-align: center;
}

.note-content-wrapper {
  padding: 15px;
}

.note-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.note-header-left {
  display: flex;
  align-items: center;
}

.note-date {
  font-size: 14px;
  color: #999;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.note-doctor {
  font-size: 14px;
  color: #666;
  background: #f5f7fa;
  padding: 4px 8px;
  border-radius: 4px;
  line-height: 1.2;
}

.note-content {
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 10px;
}

.attachment-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-top: 10px;
}

.attachment-item {
  position: relative;
  padding-bottom: 100%;
  cursor: pointer;
}

.attachment-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.attachment-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.attachment-video {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-play {
  border-style: solid;
  border-width: 10px 0 10px 16px;
  border-color: transparent transparent transparent #fff;
  margin-left: 4px;
}

.video-player-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1001;
}

.video-close-icon {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-close-icon::before,
.video-close-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
}

.video-close-icon::before {
  transform: rotate(45deg);
}

.video-close-icon::after {
  transform: rotate(-45deg);
}

.video-player {
  width: 100%;
  max-width: 800px;
}

/* 诊断信息样式 */
.diagnosis-list,
.history-list,
.allergy-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.diagnosis-item,
.history-item,
.allergy-item {
  background: #fff;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

/* 就诊记录样式 */
.visit-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.visit-item {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.visit-date {
  font-size: 14px;
  color: #999;
  margin-bottom: 8px;
}

.visit-dept {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
}

.visit-doctor {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.visit-diagnosis {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
}

/* 加载和空状态样式 */
.loading-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.loading-text {
  margin-top: 10px;
  font-size: 14px;
  color: #999;
}

.empty-state {
  text-align: center;
  padding: 20px;
  color: #999;
  font-size: 14px;
}

/* 适配APP状态 */
.no-title {
  padding-top: 0;
}
</style>
