import axios from 'axios'

const PatientNoteDao = {
  /**
   * 创建笔记
   * @param {Object} note 笔记信息
   * @param {Array} attachmentUrls 附件URL列表
   */
  async createNote (note, attachmentUrls) {
    const url = '/api/patient/note/create'
    const { data: resp } = await axios.post(url, note)
    // const { code, data } = resp
    // if (code !== 200) {
    //   return null
    // }
    return resp
  },

  /**
   * 更新笔记
   * @param {Object} note 笔记信息
   * @param {Array} attachmentUrls 附件URL列表
   */
  async updateNote (note, attachmentUrls) {
    const url = '/api/patient/note/update'
    const { data: resp } = await axios.post(url, note, {
      params: { attachmentUrls }
    })
    const { code, data } = resp
    if (code !== 200) {
      return null
    }
    return data
  },

  /**
   * 删除笔记
   * @param {number} noteId 笔记ID
   */
  async deleteNote (noteId) {
    const url = `/api/patient/note/delete/${noteId}`
    const { data: resp } = await axios.post(url)
    const { code } = resp
    return code === 200
  },

  /**
   * 获取笔记详情
   * @param {number} noteId 笔记ID
   */
  async getNoteDetail (noteId) {
    const url = `/api/patient/note/detail/${noteId}`
    const { data: resp } = await axios.get(url)
    const { code, data } = resp
    if (code !== 200) {
      return null
    }
    return data
  },

  /**
   * 分页查询医院笔记列表
   * @param {Object} params 查询参数
   */
  async getHospitalNoteList (params) {
    const url = '/api/patient/note/hospital/list'
    const { data: resp } = await axios.get(url, { params })
    const { code, data } = resp
    if (code !== 200) {
      return null
    }
    return data
  },

  /**
   * 分页查询患者笔记列表
   * @param {Object} params 查询参数
   */
  async getPatientNoteList (params) {
    const url = '/api/patient/note/patient/list'
    const { data: resp } = await axios.get(url, { params })
    const { code, data } = resp
    if (code !== 200) {
      return null
    }
    return data
  },

  /**
   * 查询医生笔记列表
   * @param {string} doctorUserId 医生用户ID
   */
  async getDoctorNoteList (doctorUserId) {
    const url = '/api/patient/note/doctor/list'
    const { data: resp } = await axios.get(url, {
      params: { doctorUserId }
    })
    const { code, data } = resp
    if (code !== 200) {
      return null
    }
    return data
  }
}

export default PatientNoteDao
