<!-- 患者笔记列表项组件 -->
<template>
  <div :class="{ 'is-demo': isDemoNote }" class="note-item">
    <!-- 年份月份显示 -->
    <div v-if="showDateDivider" class="date-divider">
      <span class="year">{{ getYear }}</span>
      <span class="month">{{ getMonth }}月</span>
    </div>
    <div class="note-content-wrapper">
      <div class="note-header">
        <div class="note-header-left">
          <div class="note-date-wrapper">
            <van-icon name="clock-o" color="#999" size="14" />
            <span class="note-date">{{ formatDateTime }}</span>
          </div>
          <span v-if="isDemoNote" class="demo-tag">演示</span>
        </div>
        <div class="note-info">
          <div class="note-patient">
            <van-icon class="patient-icon" name="friends-o" color="#666" size="14" />
            <span>患者：{{ note.patientName }}</span>
          </div>
          <div class="note-doctor">
            <van-icon class="doctor-icon" name="manager" color="#666" size="14" />
            <span>医生：{{ note.doctorName }}</span>
          </div>
        </div>
      </div>
      <div class="note-content">
        {{ note.content }}
      </div>
      <!-- 附件区域 -->
      <div v-if="note.attachments && note.attachments.length" class="attachment-grid">
        <div v-for="(item, idx) in note.attachments" :key="idx" class="attachment-item"
          @click="handleMediaClick(item, note.attachments, idx)">
          <div class="attachment-content">
            <img v-if="item.type === 'image'" :src="item.url" class="attachment-image">
            <div v-else-if="item.type === 'video'" class="attachment-video">
              <img :src="item.thumbnail" class="attachment-image">
              <div class="video-play-icon">
                <i class="icon-play"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant'

export default {
  name: 'PatientNoteItem',
  components: {
    'van-icon': Icon
  },
  props: {
    note: {
      type: Object,
      required: true
    },
    showDateDivider: {
      type: Boolean,
      default: false
    },
    isDemoNote: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getYear () {
      return new Date(this.note.recordDate).getFullYear()
    },
    getMonth () {
      return new Date(this.note.recordDate).getMonth() + 1
    },
    formatDateTime () {
      if (!this.note.recordDate) return ''
      const d = new Date(this.note.recordDate)
      const year = d.getFullYear()
      const month = String(d.getMonth() + 1).padStart(2, '0')
      const day = String(d.getDate()).padStart(2, '0')
      const hours = String(d.getHours()).padStart(2, '0')
      const minutes = String(d.getMinutes()).padStart(2, '0')
      return `${year}-${month}-${day} ${hours}:${minutes}`
    }
  },
  methods: {
    handleMediaClick (item, attachments, index) {
      this.$emit('media-click', { item, attachments, index })
    }
  }
}
</script>

<style scoped>
.note-item {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}

.note-item.is-demo::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 20px 20px 0;
  border-style: solid;
  border-color: #ff9800 #fff;
}

.date-divider {
  margin-left: 16px;
  padding: 24px 0 12px;
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
}

.date-divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background: #eee;
  margin-left: 12px;
}

.date-divider .year {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin-right: 8px;
  position: relative;
  padding-right: 12px;
}

.date-divider .year::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 12px;
  background: #ddd;
}

.date-divider .month {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  background: #f5f7fa;
  padding: 4px 12px;
  border-radius: 20px;
  min-width: 64px;
  text-align: center;
}

.note-content-wrapper {
  padding: 15px;
}

.note-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.note-header-left {
  display: flex;
  align-items: center;
}

.note-date-wrapper {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.note-date {
  font-size: 14px;
  color: #999;
  margin-left: 4px;
}

.demo-tag {
  background: #ff9800;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.note-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.note-patient,
.note-doctor {
  display: flex;
  align-items: center;
  background: #f5f7fa;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #666;
  line-height: 1.2;
}

.patient-icon,
.doctor-icon {
  margin-right: 4px;
}

.note-content {
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 10px;
}

.attachment-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-top: 10px;
}

.attachment-item {
  position: relative;
  padding-bottom: 100%;
  cursor: pointer;
}

.attachment-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.attachment-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.attachment-video {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-play {
  border-style: solid;
  border-width: 10px 0 10px 16px;
  border-color: transparent transparent transparent #fff;
  margin-left: 4px;
}
</style>
