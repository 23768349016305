<template>
  <div>
    <base-title-bar-scroll-page ref="container" :showTitleBar="!isApp" title="工作台-更多功能">
      <!-- 医生信息卡片 -->
      <div v-if="doctorInfo" class="doctor-info">
        <van-image :src="doctorAvatar" class="doctor-avatar" height="60" round width="60"/>
        <div class="doctor-info-content">
          <div class="doctor-name">{{ doctorInfo.realname }}</div>
          <div class="doctor-hospital">{{ doctorInfo.hospital || '暂无医院信息' }}</div>
        </div>
      </div>

      <van-grid :border="false" :column-num="4" :gutter="12">
        <van-grid-item v-for="(item, index) in menuList" :key="index" :icon="item.icon" :text="item.title"
                       @click="handleItemClick(item)"/>
      </van-grid>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { Grid, GridItem, Image as VanImage, Skeleton, Toast } from 'vant'
import DoctorUserDao from '@/h5/doucan/network/DoctorUserDao'

export default {
  name: 'WorkDeskMenuPage',
  components: {
    BaseTitleBarScrollPage,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [VanImage.name]: VanImage,
    [Skeleton.name]: Skeleton
  },
  data () {
    return {
      isApp: this.$route.query.isApp === 'true',
      loading: true,
      doctorInfo: null,
      defaultAvatar: 'https://cdn.keihong.tech/img/20250226183832077.png',
      menuList: [
        {
          title: '患者笔记汇总',
          icon: 'notes-o',
          path: '/patient-notes'
        },
        {
          title: '随访管理',
          icon: 'clock-o',
          path: ''
        }
        // { title: '用药提醒', icon: 'bell', path: '' },
        // { title: '检查报告', icon: 'description', path: '' },
        // { title: '就诊记录', icon: 'records', path: '' },
        // { title: '健康档案', icon: 'user-o', path: '' }
      ]
    }
  },
  computed: {
    doctorAvatar () {
      if (!this.doctorInfo) return this.defaultAvatar
      if (!this.doctorInfo.avatar) return this.defaultAvatar
      if (this.doctorInfo.avatar.startsWith('http')) return this.doctorInfo.avatar
      return process.env.VUE_APP_API_BASE_URL + this.doctorInfo.avatar
    }
  },
  async mounted () {
    // 获取并设置 token
    const token = this.$route.query.token
    console.log('token:', token)
    if (token) {
      this.$store.commit('user/setToken', token)
    }

    await this.loadDoctorInfo()
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    async loadDoctorInfo () {
      try {
        this.loading = true
        this.doctorInfo = await DoctorUserDao.getDoctorUserInfo()
        console.log('医生信息:', this.doctorInfo)
      } catch (error) {
        console.error('获取医生信息失败:', error)
        Toast('获取医生信息失败')
      } finally {
        this.loading = false
      }
    },
    handleItemClick (item) {
      if (!item.path) {
        Toast('功能开发中...')
        return
      }

      // 如果是患者笔记汇总页面
      if (item.path === '/patient-notes') {
        // 检查医生信息和医院编码
        if (!this.doctorInfo || !this.doctorInfo.hospitalCode) {
          Toast('获取医院信息失败')
          return
        }

        // 跳转到患者笔记汇总页面,带上医院ID参数
        this.$router.push({
          path: '/app_doctor_web/patient_note_summary',
          query: {
            hospital_id: this.doctorInfo.hospitalCode,
            isApp: this.isApp
          }
        })
        return
      }

      // 其他页面的跳转逻辑
      Toast('功能开发中...')
    }
  },
  watch: {}
}
</script>
<style lang="scss" scoped>
:deep(.doctor-info) {
  margin: 16px 16px 16px 16px;
  background: #fff;
  border-radius: 8px;
  padding: 12px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 20px;

  .doctor-avatar {
    flex-shrink: 0;
    margin-right: 20px;
  }

  .doctor-info-content {
    flex: 1;
    min-width: 0;

    .doctor-name {
      font-size: 16px;
      font-weight: 500;
      color: #333;
      margin-bottom: 4px;
    }

    .doctor-hospital {
      font-size: 14px;
      color: #666;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

:deep(.van-grid) {
  background-color: #f5f5f5;
  padding: 12px;

  .van-grid-item {
    &__content {
      background-color: #fff;
      border-radius: 8px;
      padding: 16px 8px;
      height: 100px;

      &::after {
        display: none;
      }

      &:active {
        opacity: 0.8;
      }
    }

    &__icon {
      font-size: 28px;
      margin-bottom: 8px;
      color: #409EFF;
    }

    &__text {
      font-size: 14px;
      color: #333;
      text-align: center;
      width: 56px;
      margin: 0 auto;
      line-height: 1.4;
      word-break: break-all;
      white-space: pre-wrap;
    }
  }
}
</style>
