import { render, staticRenderFns } from "./DoucanHome.vue?vue&type=template&id=109a1819&scoped=true"
import script from "./DoucanHome.vue?vue&type=script&lang=js"
export * from "./DoucanHome.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "109a1819",
  null
  
)

export default component.exports