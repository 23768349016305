<template>
  <!--  <div><img src="https://cdn.keihong.tech/typora/2022-10-07-feed-tips.jpeg" width="100%"></div>-->
  <keep-alive>
    <div style="width: 100%; box-sizing: border-box">
      <div
        style="
          width: 100%;
          padding-left: 16px;
          padding-top: 16px;
          padding-right: 16px;
          box-sizing: border-box;
        "
      >
        <img
          alt=""
          src="https://cdn.keihong.tech/img/202310271157460.png"
          width="100%"
          @click="showPopupKefu"/>

        <div v-show="live.show" class="live" style="margin-bottom: 16px"
             @click="liveEnter">
          <my-img :url="live.poster"/>
          <div style="font-size: 14px;text-align: center;color: #555555">（点击上方图片进入直播间）</div>
        </div>
        <!--     直播带操大入口     -->
        <div v-if="showBdjLive.includes(hospital)">
          <img src="https://cdn.keihong.tech/img/202404030904604.png" style="display: block;margin-top: 4px"
               width="100%" @click="menuLiveBdj">
        </div>
        <div v-if="hospital==='暨南大学附属顺德医院'">
          <!--扫码进群-->
          <div
            style="text-align: center;border-radius: 16px;border-width: 1px;border-color: #009477;border-style: solid;color: #00b68c;margin-top: 16px;margin-bottom: 16px;"
            @click="showGroupQRCode=true">
            点击进入暨大顺医全民主动健康群
          </div>

        </div>
      </div>
      <div v-if="showHospitalBanner"
           style="
          width: 100%;
          padding-left: 16px;
          padding-top: 16px;
          padding-right: 16px;
          box-sizing: border-box;
        "
      >
        <img
          :src="hospitalBannerUrl"
          alt=""
          width="100%"
          @click="handleClickHospitalBanner"/>
      </div>
      <div
        style="
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
          box-sizing: border-box;
        "
      >
        <div>

          <div v-if=" rehabilitationAlliance.includes(hospital)">
            <!--     南方医科大学顺德医院板块       -->
            <!--            <img v-if="hospital !== '暨南大学附属顺德医院'" alt=""-->
            <!--                 src="https://cdn.keihong.tech/img/202404231003434.png"-->
            <!--                 style="display: block;margin-top: 8px"-->
            <!--                 width="100%" @click="onAllicanceDepartmentIntroClick( {-->
            <!--          hospital: '南方医科大学顺德医院',-->
            <!--          banner0_img: 'https://cdn.keihong.tech/img/202404231003434.png',-->
            <!--          departmentIntroUrl: 'https://cdn.keihong.tech/img/202404231042844.png',-->
            <!--        })"/>-->
            <!--       暨大顺医板块     -->
            <div
              style="background-color: rgba(229,237,255,0.4);width: 100%;box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);padding:
            8px;box-sizing: border-box;border-radius: 8px;margin-top: 8px;margin-bottom: 8px;">
              <img src="https://cdn.keihong.tech/img/202310241449190.png" style="display: block" width="100%">
              <!--     横向放置两张图片，均分宽度       -->
              <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
                <img src="https://cdn.keihong.tech/img/202312181035546.png" style="display: block" width="33%"
                     @click="onDepartmentIntro1Click">
                <img src="https://cdn.keihong.tech/img/202312181043651.png" style="display: block" width="33%"
                     @click="onDepartmentIntro2Click">
                <img src="https://cdn.keihong.tech/img/202312181043218.png" style="display: block" width="33%"
                     @click="onDepartmentIntro3Click">
              </div>
            </div>
            <!--     顺德中医院、同江医院板块       -->
            <div v-show="hospital !== '暨南大学附属顺德医院'">
              <!--      rehabilitationAllianceDetails       -->
              <div v-for="(item, index) in rehabilitationAllianceDetails" :key="index">
                <img :src="item.banner0_img" width="100%" @click="onAllicanceDepartmentIntroClick(item)"/>
              </div>
              <div style="margin-top: 16px"><img
                src="https://cdn.keihong.tech/img/202401221832412.png"
                style="width: 100%;display: block"
                @click="handleClickAllianceIntro"></div>
            </div>

          </div>
          <div v-else-if="moreDepartmentHospital.includes(hospital)">

            <!--     一张主图，下面根据科室数量放置图片div，一行最多3个，若是2个，则一行均分两个       -->
            <img :src="banner0_img" style="display: block" width="100%" @click="onDepartmentIntroClick">
            <div>
              <div v-if="moreDepartmentIntroVersion===2"
                   style="display: flex; flex-direction: row; width: 100%; justify-content: space-around;flex-wrap: wrap;">
                <div v-for="(item,index) in moreDepartmentIntro" :key="index"
                     :style="{marginRight: index%3===2?'0px':'8px'}"
                     style="color: #003b8e;font-weight: bold;font-size: 14px;text-align: center;padding: 8px;
                     background-color: #efefef;border-radius: 8px;width: 100%;margin-top: 4px;
                     flex-basis: calc(33.33% - 8px); box-sizing: border-box;"
                     @click="onMoreDepartmentIntroClick(item)">
                  <div>{{ item.departmentName }}</div>
                </div>
                <!-- 添加伪元素作为占位符 -->

                <div v-for="i in (3 - moreDepartmentIntro.length % 3)" v-show="moreDepartmentIntro.length % 3 !== 0"
                     :key="i"
                     style="flex-basis: calc(33.33% - 8px);">
                </div>

              </div>
              <div v-else style="display: flex; flex-direction: row; width: 100%; justify-content: space-around">
                <div v-for="(item,index) in moreDepartmentIntro" :key="index" style="padding: 2px">
                  <img :src="item.btn" style="display: block;margin-top: 8px" width="100%"
                       @click="onMoreDepartmentIntroClick(item)"/>
                </div>
              </div>

              <!--              <img src="https://cdn.keihong.tech/img/202312181035546.png" style="display: block" width="33%"-->
              <!--                   @click="onDepartmentIntro1Click">-->
              <!--              <img src="https://cdn.keihong.tech/img/202312181043651.png" style="display: block" width="33%"-->
              <!--                   @click="onDepartmentIntro2Click">-->
              <!--              <img src="https://cdn.keihong.tech/img/202312181043218.png" style="display: block" width="33%"-->
              <!--                   @click="onDepartmentIntro3Click">-->
            </div>
          </div>
          <div v-else>
            <img v-show="banner0_img" :src="banner0_img" style="margin-top: 8px" width="100%"
                 @click="onDepartmentIntroClick"/>
          </div>
          <van-swipe v-if="banner1_img" :autoplay="3000" class="my-swipe" indicator-color="white">
            <van-swipe-item>
              <img :src="banner1_img" width="100%" @click="banner1"/>
            </van-swipe-item>
            <!-- <van-swipe-item>
            <img
              @click="banner2"
              src="https://cdn.keihong.tech/typora/2023-03-19-banner-%E6%96%B0%E5%86%A0%E8%B0%83%E6%9F%A5-2023032001.png"
              width="100%"
            />
          </van-swipe-item> -->
          </van-swipe>
        </div>
      </div>
      <div>
        <!--居家康复科普学组专栏-->
        <img
          src="https://cdn.keihong.tech/typora/2023-05-03-%E5%B1%85%E5%AE%B6%E5%BA%B7%E5%A4%8D%E7%A7%91%E6%99%AE%E5%AD%A6%E7%BB%84%E4%B8%93%E6%A0%8Fbanner-1.png"
          width="100%"
          @click="bannerJjkfkpzl"
        />
      </div>
      <div v-if="isShowDeliveryToBed" style="padding: 16px">
        <img src="https://cdn.keihong.tech/img/202312181653735.png" style="width: 100%;display: block"
             @click="menuMall">
      </div>

      <div>
        <marquee v-show="false" style="margin-bottom: 16px"></marquee>
      </div>
      <div v-show="false" style="padding: 16px; border-radius: 25px">
        <VideoPlayer
          poster-url="https://cdn.keihong.tech/img/202308072043970.png"
          videoUrl="https://cdn.keihong.tech/img/2023年8月6日-胡昔权-互联网远程康复.mp4"
        />
      </div>
      <div v-if="showSingleMall.includes(hospital)" style="padding: 16px">
        <img src="https://cdn.keihong.tech/img/202403031628368.png" style="width: 100%;display: block"
             @click="menuMall">
      </div>
      <div
        style="
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
          box-sizing: border-box;
        "
      >
        <div class="menu">
          <div v-if="hospital === '中国民间中医医药研究开发协会中蒙民族医药研究发展分会'">
            <div
              style="
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              justify-content: space-between;
            "
            >
              <!--            <img-->
              <!--              src="https://cdn.keihong.tech/img/202309271531974.png"-->
              <!--              style="width: 28vw"-->
              <!--              @click="menuMall"-->
              <!--            />-->
              <img
                src="https://cdn.keihong.tech/typora/2023-05-03-%E5%BA%B7%E5%A4%8D%E4%BD%93%E6%93%8D.png"
                style="width: 28vw"
                @click="menuTicao"
              />
              <!--            <img-->
              <!--              src="https://cdn.keihong.tech/typora/2023-05-03-%E5%BA%B7%E5%A4%8D%E4%BD%93%E6%93%8D.png"-->
              <!--              style="width: 28vw"-->
              <!--              @click="menuTicao"-->
              <!--            />-->
              <img
                src="https://cdn.keihong.tech/img/icon_kepu_teach.png"
                style="width: 28vw"
                @click="menuKepuTeach"
              />
              <!--     远程康复服务预约       -->
              <img
                src="https://cdn.keihong.tech/img/202309071931024.png"
                style="width: 28vw"
                @click="menuAppointmentQuickPay"
              />

            </div>
            <div style="height: 16px"></div>
            <div
              style="
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              justify-content: space-between;
            "
            >
              <img
                src="https://cdn.keihong.tech/img/202307042154712.png"
                style="width: 28vw"
                @click="menuLive"
              />
              <img
                src="https://cdn.keihong.tech/img/202409022332956.png"
                style="width: 28vw"
                @click="menuWenjuan"
              />

              <img
                src="https://cdn.keihong.tech/typora/2023-05-03-app%E4%B8%8B%E8%BD%BD-2.png"
                style="width: 28vw"
                @click="menu6"
              />
            </div>
            <div style="height: 16px"></div>
            <div
              id="tool"
              style="
              background-color: #DFEDF2;
              display: flex;
              padding: 16px;
              box-sizing: border-box;
              flex-direction: row;
              width: 100%;
              border-radius: 24px;
              align-items: center;
              justify-content: space-between;
            "
            >
              <img
                src="https://cdn.keihong.tech/img/202311181153398.png"
                style="width: 22vw"
                @click="menuToolKepu"
              />

              <img
                src="https://cdn.keihong.tech/img/202311181154129.png"
                style="width: 22vw;"
                @click="menuMallRent"
              />
              <!--     远程康复服务预约       -->
              <img
                src="https://cdn.keihong.tech/img/202311181154346.png"
                style="width: 22vw;"
                @click="menuMall"
              />

            </div>
            <div style="margin-top: 16px" @click="menuQuickPay"><img
              src="https://cdn.keihong.tech/img/202312211940369.png"
              style="width: 100%;display: block"></div>

          </div>
          <div v-else class="menu">
            <div
              v-if="hospital==='惠州市第三人民医院'"
              style="
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              justify-content: space-between;
            "
            >
                         <!-- <img
                           src="https://cdn.keihong.tech/img/202309271531974.png"
                           style="width: 28vw"
                           @click="menuMall"
                         /> -->
              <div
                style="width: 43vw;background-color: #E8FBF7;border-radius: 24px;display: flex;justify-content: center">
                <img
                  src="https://cdn.keihong.tech/img/202310271205979.png"
                  style="width: 28vw"
                  @click="menuToolKepu"
                />
              </div>

              <!--            <img-->
              <!--              src="https://cdn.keihong.tech/typora/2023-05-03-%E5%BA%B7%E5%A4%8D%E4%BD%93%E6%93%8D.png"-->
              <!--              style="width: 28vw"-->
              <!--              @click="menuTicao"-->
              <!--            />-->
              <div
                style="width: 43vw;background-color: #FFF1EE;border-radius: 24px;display: flex;justify-content: center">
                <img
                  src="https://cdn.keihong.tech/img/icon_kepu_teach.png"
                  style="width: 28vw"
                  @click="menuKepuTeach"
                />
              </div>

            </div>
            <div
              v-else
              style="
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              justify-content: space-between;
            "
            >
              <!--            <img-->
              <!--              src="https://cdn.keihong.tech/img/202309271531974.png"-->
              <!--              style="width: 28vw"-->
              <!--              @click="menuMall"-->
              <!--            />-->
              <img
                src="https://cdn.keihong.tech/img/202310271205979.png"
                style="width: 28vw"
                @click="menuToolKepu"
              />
              <!--            <img-->
              <!--              src="https://cdn.keihong.tech/typora/2023-05-03-%E5%BA%B7%E5%A4%8D%E4%BD%93%E6%93%8D.png"-->
              <!--              style="width: 28vw"-->
              <!--              @click="menuTicao"-->
              <!--            />-->
              <img
                src="https://cdn.keihong.tech/img/icon_kepu_teach.png"
                style="width: 28vw"
                @click="menuKepuTeach"
              />
              <!--     远程康复服务预约       -->
              <img
                src="https://cdn.keihong.tech/img/202309071931024.png"
                style="width: 28vw"
                @click="menuAppointmentQuickPay"
              />

            </div>
            <div style="height: 16px"></div>
            <div
              style="
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              justify-content: space-between;
            "
            >
              <img
                src="https://cdn.keihong.tech/img/202307042154712.png"
                style="width: 28vw"
                @click="menuLive"
              />
              <img
                src="https://cdn.keihong.tech/img/202409022332956.png"
                style="width: 28vw"
                @click="menuWenjuan"
              />

              <img
                src="https://cdn.keihong.tech/typora/2023-05-03-app%E4%B8%8B%E8%BD%BD-2.png"
                style="width: 28vw"
                @click="menu6"
              />
            </div>
            <div v-if="rentHospitals.includes(hospital)" style="margin-bottom: 16px">
              <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
                <div style="margin-right: 16px">
                  <img src="https://cdn.keihong.tech/img/202403182007254.png" style="display: block;margin-top: 8px"
                       width="100%" @click="menuMallRent"
                  />
                </div>
                <div style="">
                  <img src="https://cdn.keihong.tech/img/202403182008805.png" style="display: block;margin-top: 8px"
                       width="100%" @click="menuMall"
                  />
                </div>
              </div>

            </div>
            <!--    支付缴费        -->
            <div v-if="!notShowMenuAppointmentQuickPayShow.includes(hospital)">
              <img
                src="https://cdn.keihong.tech/img/202401071844460.png"
                style="width: 100%;margin-top: 16px"
                @click="menuPay"
              /></div>
            <!--     康复联盟       -->
            <!--            <div v-if="hospital==='暨南大学附属顺德医院'" style="margin-top: 16px"><img-->
            <!--              src="https://cdn.keihong.tech/img/202401221832412.png"-->
            <!--              style="width: 100%;display: block"-->
            <!--              @click="handleClickAllianceIntro"></div>-->
          </div>
        </div>
        <div style="height: 16px"></div>
        <div id="videolist">
          <ListPageV2/>
        </div>
      </div>
      <van-popup v-model="showKefu" position="bottom">
        <img
          alt="图片"
          src="https://cdn.keihong.tech/img/202309072319723.png"
          width="100%"
        />
      </van-popup>
      <van-popup v-model="showIntro" position="bottom">

        <div style="">
          <div style="background: transparent;text-align: center;" @click="showIntro=false">
            <img src="https://cdn.keihong.tech/img/202310301322211.png"
                 style="height: 10vh;width: 10vh;object-fit: contain">
          </div>
          <div v-if="departmentIntroLoading" style="height: 50vh;text-align: center;margin-top: 64px">
            <van-loading color="#1989fa" size="48px" type="spinner"/>
          </div>
          <div style="overflow: scroll;max-height: 90vh;;border-top-left-radius: 32px;border-top-right-radius: 32px">
            <div v-if="departmentIntroUrls.length>0">
              <div v-for="(url,index) in departmentIntroUrls" :key="index">
                <img :src="url" style="width: 100%;display: block;" @load="departmentIntroLoading=false"/>
              </div>
              <div style="text-align: center;font-size: 12px;color: #4e4e4e;background-color: white;padding: 16px">
                技术支持：动优远程康复服务平台
              </div>
            </div>
            <div v-else>
              <img
                :src="departmentIntroUrl"
                alt="图片"
                width="100%"
                @load="departmentIntroLoading=false"
              />
            </div>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="showGroupQRCode" position="bottom" style="background-color: white">
        <div style="text-align: center;color: #00ad61">长按二维码进群</div>
        <van-image :src="groupQRCodeUrl" fit="contain" style="margin: 16px"/>
        <van-button block round type="normal" @click="showGroupQRCode=false">关闭</van-button>
        <div style="height: 16px"></div>
      </van-popup>
    </div>
  </keep-alive>
</template>

<script>
import ListPageV2 from '../../../feeds/ListPageV2.vue'
import Marquee from '../../components/Marquee.vue'
import VideoPlayer from '../../../../common-components/VideoPlayer/VideoPlayer.vue'
import Fingerprint2 from 'fingerprintjs2'
import axios from 'axios'
import DescpDao from '@/h5/doucan/network/DescpDao'
import ReportFeatureAccessDao from '@/h5/doucan/network/ReportFeatureAccessDao'
import MyImg from '@/h5/doucan/components/MyImg.vue'
import { isWechatBrowser } from '@/utils/BrowserUtils'

export default {
  name: 'Square',
  components: {
    MyImg,
    ListPageV2,
    Marquee,
    VideoPlayer
  },
  data () {
    return {

      live: {
        show: false,
        poster: 'https://cdn.keihong.tech/img/202408011554109.png',
        url: 'https://wx.vzan.com/live/page/379090788?v=1722498250279',
        liveHospital: ['动优康复']
      },
      notShowMenuAppointmentQuickPayShow: ['惠州市第三人民医院', '暨南大学附属顺德医院'],
      showGroupQRCode: false,
      rentHospitals: ['中山大学附属第三医院康复医学科'],
      moreDepartmentHospital: [],
      moreDepartmentIntro: [],
      moreDepartmentIntroVersion: -1,
      showSingleMall: ['广东同江医院', '南方医科大学顺德医院', '佛山正骨骨科医院'],
      showBdjLive: ['暨南大学附属顺德医院', '动优康复'],
      rehabilitationAlliance: ['广东同江医院', '暨南大学附属顺德医院'], // '广州中医药大学顺德医院', '暨南大学附属顺德医院'
      rehabilitationAllianceDetails: [
        // 广州中医药大学顺德医院
        {
          hospital: '广州中医药大学顺德医院',
          banner0_img: 'https://cdn.keihong.tech/img/202310080959762.png',
          departmentIntroUrl: 'https://cdn.keihong.tech/img/202407241531163.png',
          banner1_route: '/ServeSale202301?from=' + this.from
        },
        {
          hospital: '广东同江医院',
          banner0_img: 'https://cdn.keihong.tech/img/202401101715982.png',
          departmentIntroUrl: 'https://cdn.keihong.tech/img/202401101734161.png',
          banner1_route: '/ServeSale202301?from=' + this.from
        }

      ],
      departmentIntroLoading: true,
      from: '',
      banner0_img: '',
      banner1_img: '',
      banner1_route: '',
      hospital: '',
      showKefu: false,
      showHospitalBanner: false,
      hospitalBannerUrl: '',
      hospitalBannerRoute: '',
      departmentIntroUrl: '',
      departmentIntroUrl1: '',
      departmentIntroUrl2: '',
      departmentIntroUrl3: '',
      departmentIntroUrls: [],
      showIntro: false,
      showDeliveryToBedHospitals: ['暨南大学附属顺德医院1'],
      isShowDeliveryToBed: false,
      groupQRCodeUrl: '',
      openid: null,
      urlNow: ''
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave 康复圈page')
    // 保存当前滚动位置
    const scrollPosition = {
      x: window.pageXOffset,
      y: window.pageYOffset
    }
    sessionStorage.setItem('scrollPosition', JSON.stringify(scrollPosition))
    next()
  },
  mounted () {
    this.postRecord()
    console.log('mounted 康复圈page')
    // 把this.hospital做成一个json文本，赋给剪切板
    // todo 待优化
    // setTimeout(() => {
    //   // 在延迟后执行的代码
    //   // 可以在这里调用其他方法或处理逻辑
    //   const scrollPosition = JSON.parse(
    //     sessionStorage.getItem('scrollPosition')
    //   )

    //   if (scrollPosition) {
    //     console.log('康复圈page ', scrollPosition)
    //     window.scrollTo(scrollPosition.x, scrollPosition.y)
    //   }
    // }, 800)
    console.log('上级页面url', this.$store.state.route.from)
  },
  created () {
    this.urlNow = encodeURIComponent(window.location.href)
    this.getQrCodeUrl()
    console.log('created 康复圈page')
    // console.log('created from', this.$route.query.from)
    this.from = this.$route.query.from
    this.hospital = this.$route.query.hospital
    // 扫码进入的，二维码有医院标志
    if (this.hospital) {
      // 持久化当前的医院
      this.$store.commit('user/setQrCodeHospital', this.hospital)

      console.log('持久化', this.hospital)
    } else {
      // 非扫码进入或已经点击了其他页面回到科普页面
      // 检查当前是否有持久化的医院
      const qrCodeHospital = this.$store.state.user.qrCodeHospital
      if (qrCodeHospital) {
        this.hospital = qrCodeHospital
      }
    }

    if (!this.hospital) {
      this.hospital = '动优康复'
    }

    if (this.$store.state.notShowMallHospitals.includes(this.hospital)) {
      this.$store.commit('user/setCanShowMall', false)
    } else {
      this.$store.commit('user/setCanShowMall', true)
    }

    this.checkSaleActivityBanner()
    this.checkHospitalBanner()
    this.checkDeliveryToBed()

    // if (!DEV.DEBUG) {
    //   this.doGetOpenID()
    // }

    if (isWechatBrowser()) {
      this.doGetOpenID()
    }

    const i = 3 - (this.moreDepartmentIntro.length % 3)
    console.log('i', i)
    console.log('test', this.moreDepartmentIntro.length % 3 !== 0)
  },
  methods: {
    async doGetOpenID () {
      await this.getAppid()
      const openid = this.$store.state.user.openId
      if (openid) {
        this.openid = openid
      } else {
        await this.getMpOpenID()
      }
    },
    async getOpenId (code) {
      // this.info = '正在获取getOpenId'
      const url = '/quick/pay/openid?code=' + code

      axios
        .get(url)
        .then((res) => {
          // this.info = JSON.stringify(res)
          this.openid = res.data.data.openid
          console.log('openid = ', res.data.data.openid)
          if (!this.openid) {
            this.getCodeApi()
            return
          }
          this.$store.commit('user/setOpenId', this.openid)
          // const profile = this.$store.state.apply20230001.profile
          // profile.openId = this.openid
          // this.$store.commit('apply20230001/update', profile)
        })
        .catch((err) => {
          console.log('err', err)
          this.getCodeApi()
        })
    },
    getCodeApi () {
      const appid = 'wxcb56667c309e369b'
      const scope = 'snsapi_base' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      // const scope = 'snsapi_userinfo' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      const url =
        'https://open.weixin.qq.com' +
        '/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        this.urlNow +
        '&response_type=code&scope=' +
        scope +
        '#wechat_redirect'
      window.location.href = url
    },
    getUrlKey (name) {
      // 获取url 参数
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [undefined, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    async getAppid () {
      const url = '/quick/pay/appid'
      const res = await axios.get(url)
      console.log('appid', res)
      this.appid = res.data.data.codeName
      this.appsecret = res.data.data.codeValue
    },
    async getMpOpenID () {
      const code = this.getUrlKey('code')
      if (code) {
        this.code = code
        await this.getOpenId(code)
      } else {
        this.getCodeApi()
      }
    },
    reportFeatureAccess () {
      console.log('上级页面url', this.$store.state.route.from)
      if (this.$store.state.route.from === '/') {
        ReportFeatureAccessDao.report(4, this.hospital, this.openid).then(res => {
          console.log('上报访问成功', res)
        })
      }
    },
    getQrCodeUrl () {
      DescpDao.findByTaxon('sdyy_wx_group_qr_code').then(res => {
        // console.log('二维码', res)
        this.groupQRCodeUrl = res
      })
    },
    handleClickAllianceIntro () {
      this.$router.push('/AllianceIntroPage')
    },
    checkLogin () {
      return this.$store.state.user.profile.isLogin
    },
    menuPay () {
      if (!this.checkLogin()) {
        this.$store.commit('setAfterLogin', 'DoctorPaymentBillList')
        this.$router.push('/login/index')
        return
      }
      this.$router.push('/DoctorPatientPaymentBillListForPatient?patientId=' + this.$store.state.user.profile.userId)
    },
    menuQuickPay () {
      window.location.href = 'https://doucan.jianerkanggz.cn/#/QuickPayPage?hospital=%E5%86%85%E8%92%99%E5%8F%A4%E7%9B%8A%E6%B5%8E%E5%A0%82%E5%85%BB%E8%80%81%E6%9C%8D%E5%8A%A1%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8'
    },
    checkDeliveryToBed () {
      // 当前医院是否支持送床上
      if (this.showDeliveryToBedHospitals.includes(this.hospital)) {
        this.isShowDeliveryToBed = true
      }
    },
    menuToolKepu () {
      // toast 辅具科普
      this.$toast('辅具科普')
      // 跳转 ToolKepuHomePage
      this.$router.push('/ToolKepuHomePage')
    },
    onDepartmentIntro1Click () {
      this.departmentIntroUrl = this.departmentIntroUrl1
      this.showIntro = true
      console.log('onDepartmentIntro1Click', this.departmentIntroUrl)
    },
    onDepartmentIntro2Click () {
      this.departmentIntroUrl = this.departmentIntroUrl2
      this.showIntro = true
    },
    onDepartmentIntro3Click () {
      this.departmentIntroUrl = this.departmentIntroUrl3
      this.showIntro = true
    },
    postRecord () {
      Fingerprint2.get((components) => {
        const values = components.map(function (component, index) {
          if (index === 0) { // 把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
            return component.value.replace(/\bNetType\/\w+\b/, '')
          }
          return component.value
        })

        const fingerprint = Fingerprint2.x64hash128(values.join(''), 31)
        console.log('Current Device Unique ID:', fingerprint)
        const currentUrl = decodeURIComponent(window.location.href)
        const userAgent = window.navigator.userAgent
        console.log('Current URL:', currentUrl)
        console.log('Current userAgent:', userAgent)
        // post 请求url/accessRecord/save
        axios.post('/accessRecord/save', {
          url: currentUrl,
          userAgent: userAgent,
          fingerprint2: fingerprint
        })
      })
    },
    onAllicanceDepartmentIntroClick (data) {
      this.departmentIntroUrl = data.departmentIntroUrl
      this.showIntro = true
      console.log('onAllicanceDepartmentIntroClick', this.departmentIntroUrl)
    },
    onMoreDepartmentIntroClick (data) {
      // 若data.intro是数组
      if (Array.isArray(data.intro)) {
        this.departmentIntroUrls = data.intro
      } else {
        this.departmentIntroUrl = data.intro
      }

      this.showIntro = true
      console.log('onMoreDepartmentIntroClick', data)
    },
    onDepartmentIntroClick () {
      if (this.departmentIntroUrl) {
        this.showIntro = true
        return
      }
      if (this.departmentIntroUrls.length > 0) {
        this.showIntro = true
      }
    },
    checkHospitalBanner () {
      if (this.hospital === '南方医科大学第七附属医院') {
        this.showHospitalBanner = true
        this.hospitalBannerUrl = 'https://cdn.keihong.tech/img/202309251109181.png'
        this.hospitalBannerRoute = 'https://mp.weixin.qq.com/s/VOI6kI_UZsw_E1O3yEy3Qw'
      }
      if (this.hospital === '沈阳金辉脊柱康复中心') {
        this.showHospitalBanner = true
        this.hospitalBannerUrl = 'https://cdn.keihong.tech/img/202310131425943.png'
        this.hospitalBannerRoute = 'https://wx.vzan.com/live/channelpage-268366?v=1697167659534'
      }

      // if (this.hospital === '暨南大学附属顺德医院') {
      //   this.showHospitalBanner = true
      //   this.hospitalBannerUrl = 'https://cdn.keihong.tech/img/202310131425943.png'
      //   this.hospitalBannerRoute = 'https://wx.vzan.com/live/channelpage-268366?v=1697167659534'
      // }
    },
    handleClickHospitalBanner () {
      window.location.href = this.hospitalBannerRoute
    },
    copyTextToClipboard () {
      const message = JSON.stringify({
        fromHospital: this.hospital
      })
      this.$copyText(message).then(e => {
        console.log('剪切板', e)
      }, function (e) {
        alert('医院定位失败')
        console.log(e)
      })
    },
    checkSaleActivityBanner () {
      if (this.rehabilitationAlliance.includes(this.hospital)) {
        this.departmentIntroUrl1 = 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/pic/%E9%A1%BA%E5%BE%B7%E5%B0%8F%E7%A8%8B%E5%BA%8F/sdyy-doctor-20211107-1.png'
        this.departmentIntroUrl2 = 'https://cdn.keihong.tech/img/202310241533570.png'
        this.departmentIntroUrl3 = 'https://cdn.keihong.tech/img/202312181056649.png'
      }

      // U康来子医院
      if (this.hospital === '上海中医药大学协爱栋安中医医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202407091654656.png'
        // 医院科室总介绍图
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202407161057806.png'

        // 多科室模式
        this.moreDepartmentHospital.push(this.hospital)
        this.moreDepartmentIntro.push({
          btn: 'https://cdn.keihong.tech/img/202407161037060.png',
          intro: 'https://cdn.keihong.tech/img/202407161049982.png'
        })

        return
      }

      if (this.hospital === '乐扬运动康复') {
        // 医院logo banner
        this.banner0_img = '  https://cdn.keihong.tech/img/202409041347639.png'
        // 医院科室介绍图
        /**
         * https://cdn.keihong.tech/img/202409041355214.jpg
         * https://cdn.keihong.tech/img/202409041355216.jpg
         * https://cdn.keihong.tech/img/202409041355217.jpg
         * https://cdn.keihong.tech/img/202409041355218.jpg
         * https://cdn.keihong.tech/img/202409041355219.jpg
         * https://cdn.keihong.tech/img/202409041355220.jpg
         * https://cdn.keihong.tech/img/202409041355221.jpg
         * https://cdn.keihong.tech/img/202409041355222.jpg
         * https://cdn.keihong.tech/img/202409041355223.jpg
         * @type {*[]}
         */
        const imageUrls = [
          'https://cdn.keihong.tech/img/202409041355214.jpg',
          'https://cdn.keihong.tech/img/202409041355216.jpg',
          'https://cdn.keihong.tech/img/202409041355217.jpg',
          'https://cdn.keihong.tech/img/202409041355218.jpg',
          'https://cdn.keihong.tech/img/202409041355219.jpg',
          'https://cdn.keihong.tech/img/202409041355220.jpg',
          'https://cdn.keihong.tech/img/202409041355221.jpg',
          'https://cdn.keihong.tech/img/202409041355222.jpg',
          'https://cdn.keihong.tech/img/202409041355223.jpg'
        ]

        this.departmentIntroUrls = imageUrls

        console.log('departmentIntroUrls', this.departmentIntroUrls)
        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === 'U康来互联网医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202407091654656.png'
        // 医院科室介绍图

        const imageUrls = [
          'https://cdn.keihong.tech/img/202407091642449.png',
          'https://cdn.keihong.tech/img/202407091642450.png',
          'https://cdn.keihong.tech/img/202407091642451.png',
          'https://cdn.keihong.tech/img/202407091642452.png'
        ]

        this.departmentIntroUrls = imageUrls

        console.log('departmentIntroUrls', this.departmentIntroUrls)
        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '汕头大学医学院第一附属医院') {
        // 医院logo banner
        this.banner0_img = '  https://cdn.keihong.tech/img/202407121938659.png'
        // 医院科室介绍图

        const imageUrls = [
          'https://cdn.keihong.tech/img/202407121944459.png',
          'https://cdn.keihong.tech/img/202407121944460.png',
          'https://cdn.keihong.tech/img/202407121944461.png'
        ]

        this.departmentIntroUrls = imageUrls

        console.log('departmentIntroUrls', this.departmentIntroUrls)
        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '东莞市寮步医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202407281017526.png'
        // 医院科室介绍图

        /**
         * https://cdn.keihong.tech/img/202407281011652.png
         * https://cdn.keihong.tech/img/202407281011653.png
         * https://cdn.keihong.tech/img/202407281011654.png
         * https://cdn.keihong.tech/img/202407281011655.png
         * https://cdn.keihong.tech/img/202407281011656.png
         * https://cdn.keihong.tech/img/202407281011657.png
         * https://cdn.keihong.tech/img/202407281011658.png
         * https://cdn.keihong.tech/img/202407281011659.png
         * https://cdn.keihong.tech/img/202407281011660.png
         * https://cdn.keihong.tech/img/202407281011661.png
         * https://cdn.keihong.tech/img/202407281011662.png
         * https://cdn.keihong.tech/img/202407281011663.png
         * @type {string[]}
         */
        const imageUrls = [
          'https://cdn.keihong.tech/img/202407281011652.png',
          'https://cdn.keihong.tech/img/202407281011653.png',
          'https://cdn.keihong.tech/img/202407281011654.png',
          'https://cdn.keihong.tech/img/202407281011655.png',
          'https://cdn.keihong.tech/img/202407281011656.png',
          'https://cdn.keihong.tech/img/202407281011657.png',
          'https://cdn.keihong.tech/img/202407281011658.png',
          'https://cdn.keihong.tech/img/202407281011659.png',
          'https://cdn.keihong.tech/img/202407281011660.png',
          'https://cdn.keihong.tech/img/202407281011661.png',
          'https://cdn.keihong.tech/img/202407281011662.png',
          'https://cdn.keihong.tech/img/202407281011663.png'
        ]

        this.departmentIntroUrls = imageUrls

        console.log('departmentIntroUrls', this.departmentIntroUrls)
        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '龙脊康') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202407280950045.png'
        // 医院科室介绍图

        /**
         * https://cdn.keihong.tech/img/202407280953500.jpg
         * https://cdn.keihong.tech/img/202407280953501.jpg
         * https://cdn.keihong.tech/img/202407280953502.jpg
         * https://cdn.keihong.tech/img/202407280953503.jpg
         * @type {string[]}
         */
        const imageUrls = [
          'https://cdn.keihong.tech/img/202407280953500.jpg',
          'https://cdn.keihong.tech/img/202407280953501.jpg',
          'https://cdn.keihong.tech/img/202407280953502.jpg',
          'https://cdn.keihong.tech/img/202407280953503.jpg'
        ]

        this.departmentIntroUrls = imageUrls

        console.log('departmentIntroUrls', this.departmentIntroUrls)
        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '东莞市桥头医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202406251532506.png'
        // 医院科室介绍图

        const imageUrls = [
          'https://cdn.keihong.tech/img/202406251537448.png',
          'https://cdn.keihong.tech/img/202406251537449.png',
          'https://cdn.keihong.tech/img/202406251537432.png',
          'https://cdn.keihong.tech/img/202406251537433.png',
          'https://cdn.keihong.tech/img/202406251537434.png',
          'https://cdn.keihong.tech/img/202406251537435.png',
          'https://cdn.keihong.tech/img/202406251537436.png',
          'https://cdn.keihong.tech/img/202406251537437.png',
          'https://cdn.keihong.tech/img/202406251537438.png',
          'https://cdn.keihong.tech/img/202406251537439.png',
          'https://cdn.keihong.tech/img/202406251537440.png',
          'https://cdn.keihong.tech/img/202406251537441.png',
          'https://cdn.keihong.tech/img/202406251537442.png',
          'https://cdn.keihong.tech/img/202406251537443.png',
          'https://cdn.keihong.tech/img/202406251537445.png',
          'https://cdn.keihong.tech/img/202406251537446.png',
          'https://cdn.keihong.tech/img/202406251537447.png'
        ]

        this.departmentIntroUrls = imageUrls

        console.log('departmentIntroUrls', this.departmentIntroUrls)
        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '越秀银幸') {
        this.banner0_img = 'https://cdn.keihong.tech/img/202410090807198.png'
        // this.banner1_img = 'https://cdn.keihong.tech/img/202306241250925.png'
        // this.banner1_route = '/salepage/SalePageDongguangkanghua01'

        // 多科室模式
        this.moreDepartmentIntro.push({
          departmentName: '越秀银幸',
          /**
             * https://cdn.keihong.tech/img/202410090825984.jpg
             * https://cdn.keihong.tech/img/202410090825985.jpg
             * https://cdn.keihong.tech/img/202410090825986.jpg
             * https://cdn.keihong.tech/img/202410090825987.jpg
             * https://cdn.keihong.tech/img/202410090825988.jpg
             * https://cdn.keihong.tech/img/202410090825989.jpg
             */
          intro: [
            'https://cdn.keihong.tech/img/202410090825984.jpg',
            'https://cdn.keihong.tech/img/202410090825985.jpg',
            'https://cdn.keihong.tech/img/202410090825986.jpg',
            'https://cdn.keihong.tech/img/202410090825987.jpg',
            'https://cdn.keihong.tech/img/202410090825988.jpg',
            'https://cdn.keihong.tech/img/202410090825989.jpg'
          ]
        },
        {
          departmentName: '医疗服务',
          intro: [
            'https://cdn.keihong.tech/img/202410090826367.png',
            'https://cdn.keihong.tech/img/202410090826368.png',
            'https://cdn.keihong.tech/img/202410090826369.png'

          ]
        },
        {
          departmentName: '养老服务',
          intro: [
            'https://cdn.keihong.tech/img/202410090828436.png',
            'https://cdn.keihong.tech/img/202410090828437.png'

          ]
        })

        this.moreDepartmentHospital.push(this.hospital)
        this.moreDepartmentIntroVersion = 2

        return
      }

      if (this.hospital === '东莞康华医院') {
        this.banner0_img = 'https://cdn.keihong.tech/img/202406131117019.jpg'
        this.banner1_img = 'https://cdn.keihong.tech/img/202306241250925.png'
        this.banner1_route = '/salepage/SalePageDongguangkanghua01'

        // 多科室模式
        this.moreDepartmentIntro.push({
          departmentName: '康复医学科',
          intro: [
            'https://cdn.keihong.tech/img/202406121337735.png',
            'https://cdn.keihong.tech/img/202406121337736.png',
            'https://cdn.keihong.tech/img/202406121337737.png',
            'https://cdn.keihong.tech/img/202406121337738.png',
            'https://cdn.keihong.tech/img/202406121337740.png',
            'https://cdn.keihong.tech/img/202406121337741.png',
            'https://cdn.keihong.tech/img/202406121337742.png',
            'https://cdn.keihong.tech/img/202406121337743.png'
          ]
        },
        {
          departmentName: '神经内科',
          intro: [
            'https://cdn.keihong.tech/img/202406131045888.png',
            'https://cdn.keihong.tech/img/202406131046870.png'

          ]
        },
        {
          departmentName: '脊柱关节外科',
          intro: [
            'https://cdn.keihong.tech/img/202406131141339.png',
            'https://cdn.keihong.tech/img/202406131142091.png'

          ]
        },
        {
          departmentName: '创伤骨科',
          intro: [
            'https://cdn.keihong.tech/img/202406131143103.png',
            'https://cdn.keihong.tech/img/202406131144216.png'

          ]
        })

        this.moreDepartmentHospital.push(this.hospital)
        this.moreDepartmentIntroVersion = 2

        return
      }

      if (this.hospital === '广东省第一荣军医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202406020730841.png'
        // 医院科室总介绍图
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202406020730841.png'

        // 多科室模式
        this.moreDepartmentHospital.push(this.hospital)
        this.moreDepartmentIntro.push({
          btn: 'https://cdn.keihong.tech/img/202406020739152.png',
          intro: 'https://cdn.keihong.tech/img/202406020756867.png'
        },
        {
          btn: 'https://cdn.keihong.tech/img/202406020738863.png',
          intro: 'https://cdn.keihong.tech/img/202406020818103.png'
        },
        {
          btn: 'https://cdn.keihong.tech/img/202406020737532.png',
          intro: 'https://cdn.keihong.tech/img/202406020819026.png'
        })

        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '南华大学附属第二医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202405061531846.png'
        // 医院科室介绍图
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202405061614940.png'

        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '广东崇爱康复医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202406112037486.png'
        // 医院科室介绍图
        /**
         * https://cdn.keihong.tech/img/202406112043079.png
         * https://cdn.keihong.tech/img/202406112043080.png
         * https://cdn.keihong.tech/img/202406112043081.png
         * https://cdn.keihong.tech/img/202406112043082.png
         * https://cdn.keihong.tech/img/202406112043083.png
         * https://cdn.keihong.tech/img/202406112043084.png
         * https://cdn.keihong.tech/img/202406112043085.png
         */
        this.departmentIntroUrls.push(
          'https://cdn.keihong.tech/img/202406112043079.png',
          'https://cdn.keihong.tech/img/202406112043080.png',
          'https://cdn.keihong.tech/img/202406112043081.png',
          'https://cdn.keihong.tech/img/202406112043082.png',
          'https://cdn.keihong.tech/img/202406112043083.png',
          'https://cdn.keihong.tech/img/202406112043084.png',
          'https://cdn.keihong.tech/img/202406112043085.png'
        )
        console.log('departmentIntroUrls', this.departmentIntroUrls)
        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '南方医科大学顺德医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202404231003434.png'
        // 医院科室介绍图
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202404231042844.png'

        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '瑞合康复') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202403081605270.png'
        // 医院科室总介绍图
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202403081651495.png'

        // 多科室模式
        this.moreDepartmentHospital.push(this.hospital)
        this.moreDepartmentIntro.push({
          btn: 'https://cdn.keihong.tech/img/202403081710783.png',
          intro: 'https://cdn.keihong.tech/img/202403081651495.png'
        },
        {
          btn: 'https://cdn.keihong.tech/img/202403081710020.png',
          intro: 'https://cdn.keihong.tech/img/202403081651495.png'
        })

        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '惠州市第三人民医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202404181636534.png'
        // 医院科室介绍图
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202404181646961.png'

        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '佛山正骨骨科医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202404231849336.png'
        // 医院科室介绍图
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202404231858857.png'

        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '南康金鸡医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202403031642558.png'
        // 医院科室介绍图
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202403031651877.png'

        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '开强正康医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202403031531927.png'
        // 医院科室介绍图
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202403031618469.png'

        // 活动入口图
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '广州中医药大学顺德医院') {
        // 医院logo banner
        this.banner0_img = 'https://cdn.keihong.tech/img/202310080959762.png'
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202407241531163.png'
        // this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        // this.banner1_img = 'https://cdn.keihong.tech/img/202401031637609.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from
        // this.banner1_route = '/SalePageFor480'
        return
      }

      if (this.hospital === '暨南大学附属顺德医院') {
        this.isShowDeliveryToBed = true
        this.banner0_img = 'https://cdn.keihong.tech/img/202310140005645.png'
        // this.banner1_img = 'https://cdn.keihong.tech/img/202306261838219.png'
        // this.banner1_route = '/salepage/20230001'
        this.departmentIntroUrl1 = 'https://cdn.keihong.tech/img/202406071139917.png'
        this.departmentIntroUrl2 = 'https://cdn.keihong.tech/img/202310241533570.png'
        this.departmentIntroUrl3 = 'https://cdn.keihong.tech/img/202312181056649.png'
        return
      }
      if (this.hospital === '中国民间中医医药研究开发协会中蒙民族医药研究发展分会') {
        this.banner0_img = 'https://cdn.keihong.tech/img/202311182210528.png'
        this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.banner1_route = '/salepage/20230001'
        return
      }

      if (this.hospital === '广州活动2023-001') {
        this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from
        return
      }

      if (this.hospital === '广州和平骨科医院') {
        this.banner0_img = 'https://cdn.keihong.tech/img/202309071156245.png'
        this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202405091548304.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from
        return
      }

      if (this.hospital === '暨南大学附属第一医院') {
        this.banner0_img = 'https://cdn.keihong.tech/img/202309211707325.png'
        this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from
        return
      }

      if (this.hospital === '沈阳金辉脊柱康复中心') {
        this.banner0_img = 'https://cdn.keihong.tech/img/202309162330809.png'
        this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from
        return
      }

      if (this.hospital === '深圳龙城医院') {
        this.banner0_img = 'https://cdn.keihong.tech/img/202310301227492.jpg'
        this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202310301312016.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from
        return
      }

      if (this.hospital === '广东同江医院') {
        this.banner0_img = 'https://cdn.keihong.tech/img/202401101715982.png'
        // this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202401101734161.png'
        // this.banner1_route = '/ServeSale202301?from=' + this.from

        return
      }

      if (this.hospital === '佛山复星禅诚医院') {
        this.banner0_img = 'https://cdn.keihong.tech/img/202401111952369.png'
        this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202401111951546.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from
        return
      }

      if (this.hospital === '南方医院白云分院（沙河院区）') {
        this.banner0_img = 'https://cdn.keihong.tech/img/202312051952609.png'
        this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202312052223655.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from
        return
      }

      if (this.hospital === '江门市妇幼保健院') {
        this.banner0_img = 'https://cdn.keihong.tech/img/202312061656105.png'
        // this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.departmentIntroUrl = 'https://cdn.keihong.tech/img/202312061711246.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from
        return
      }

      if (this.hospital === '动优') {
        this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from
        return
      }

      // 有医院渠道，但没有活动匹配，显示默认的
      if (this.hospital) {
        this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
        this.banner1_route = '/ServeSale202301?from=' + this.from
        return
      }

      this.banner1_img = 'https://cdn.keihong.tech/img/202306042020068.png'
      this.banner1_route = '/ServeSale202301?from=' + this.from
    },
    showPopupKefu () {
      this.showKefu = true
    },
    banner1 () {
      if (this.banner1_route === '') {
        return
      }
      this.$router.push(this.banner1_route)
    },
    banner2 () {
      // this.$router.push('/ServeSale202301')
      window.location.href = 'https://mp.weixin.qq.com/s/NREljQjyCSy9C-6_NQwGDw'
      // this.$router.push(
      //   '/WebPage?title=居家康复问卷活动&src=https://mp.weixin.qq.com/s/NREljQjyCSy9C-6_NQwGDw'
      // )
    },
    bannerJjkfkpzl () {
      // 居家康复学组专栏
      // this.$router.push('/jjkfkp/KepuColumn')
      // this.$router.push(
      //   '/WebPage?title=居家康复学组专栏&src=https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzI3NjczMDc5Mg==&action=getalbum&album_id=2911239609479577601#wechat_redirect'
      // )
      window.location.href =
        'https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzI3NjczMDc5Mg==&action=getalbum&album_id=2911239609479577601#wechat_redirect'
    },
    mallNormal () {
      // 跳转到 MallMainPage
      this.$router.push('/MallMainPage')
    },
    menuMall () {
      // if (this.hospital === '中国民间中医医药研究开发协会中蒙民族医药研究发展分会') {
      //   this.$router.push('/MallMainPageNeimeng')
      //   return
      // }
      // // 跳转到 MallMainPage
      // this.$router.push('/MallMainPage')

      this.$router.push('/MallMainPageNeimeng')
    },
    menuMallRent () {
      // 跳转到 MallMainPage
      this.$router.push('/MallRentMainPageNeimeng')
    },
    menu1 () {
      // document.getElementById('videolist').scrollIntoView()
      const element = document.getElementById('videolist')
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth' // 可选，平滑滚动
      })
    },
    menuLive () {
      // this.$router.push('/jjkfkp/KepuLive')
      window.location.href = 'https://wx.vzan.com/live/livedetail-265220304'
      // this.$router.push(
      //   '/WebPage?title=直播&src=https://wx.vzan.com/live/livedetail-265220304')
    },
    menuLiveBdj () {
      // this.$router.push('/jjkfkp/KepuLive')
      window.location.href = 'https://wx.vzan.com/live/page/1610656025?v=1711333435126'
      // this.$router.push(
      //   '/WebPage?title=直播&src=https://wx.vzan.com/live/livedetail-265220304')
    },
    menuAppointmentQuickPay () {
      this.$router.push('/payserve1')
    },
    menuWenjuan () {
      // this.banner2()
      // 问卷 改为 远程康复案例
      this.$router.push('/introPage?isWeb=true')
    },
    menu6 () {
      this.copyTextToClipboard()
      this.$router.push('/hospital_intro/doucan')
    },
    menuTicao () {
      this.$router.push('/ticaoList')
    },
    menuKepuTeach () {
      if (this.hospital === '中国民间中医医药研究开发协会中蒙民族医药研究发展分会') {
        const element = document.getElementById('videolist')
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth' // 可选，平滑滚动
        })
      } else {
        this.$router.push('/KepuxuanjiaoMainPage')
      }
    },
    liveEnter () {
      window.location.href = this.live.url
    }
  },
  watch: {
    openid: {
      handler (newValue) {
        if (this.openid) {
          this.reportFeatureAccess()
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.my-swipe .van-swipe-item {
}

.van-popup {
  background-color: transparent;
}
</style>
