import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
// 引入vuex持久化方法createPersistedState
import createPersistedState from 'vuex-persistedstate'
import login from './modules/login'
import play from './modules/play'
import device from './modules/device'
import apply20230001 from './modules/apply20230001'
import loginAdmin from '@/h5/doucan/store/modules/loginAdmin'
import mall from '@/h5/doucan/store/modules/mall'
import distributor from '@/h5/doucan/store/modules/distributor'
import giftCard from '@/h5/doucan/store/modules/giftCard'
import route from '@/h5/doucan/store/modules/route'
import assessment from '@/h5/doucan/store/modules/assessment'
import miniappWebHospitalList from '@/h5/doucan/store/modules/miniappWebHospitalList'
import globalData from '@/h5/doucan/store/modules/globalData'

Vue.use(Vuex)
const store = new Vuex.Store({
  state () {
    return {
      // 就是公共的数据，所有的组件都可以直接使用
      // 就是公共的数据，所有的组件都可以直接使用
      isApp: false,
      patientDataEntryClerk: {
        userId: '',
        name: '',
        hospitalId: ''
      },
      afterLogin: null,
      scrollY: 0,
      isWxapp: false,
      wxAppOpenId: '',
      notShowMallHospitals: ['广州中医药大学顺德医院', '广东省第一荣军医院', '东莞市桥头医院'],
      status: {
        back2: false
      }
    }
  },
  mutations: {

    setWxAppOpenId (state, payload) {
      state.wxAppOpenId = payload
    },
    setBack2 (state, payload) {
      state.status.back2 = payload
    },
    setIsWxapp (state, payload) {
      state.isWxapp = payload
    },
    setScrollY (state, payload) {
      state.scrollY = payload
    },
    setAfterLogin (state, payload) {
      state.afterLogin = payload
    },
    setIsApp: function (flag) {
      this.state.isApp = flag
    },
    setGymnasticsDetail (state, payload) {
      state.gymnasticsDetail = payload
    },
    setPlanDetail (state, payload) {
      state.planDetail = payload
    },
    setPatientDataEntryClerk (state, payload) {
      state.patientDataEntryClerk.userId = payload.userId
      state.patientDataEntryClerk.name = payload.name
      state.patientDataEntryClerk.hospitalId = payload.hospitalId
    }
  },
  actions: {},
  getters: {},
  modules: {
    // 模块化数据
    user,
    login,
    play,
    device,
    apply20230001,
    loginAdmin,
    mall,
    distributor,
    giftCard,
    route,
    assessment,
    miniappWebHospitalList,
    globalData
  },
  plugins: [
    // veux持久化配置
    createPersistedState({
      key: 'doucanstore-client',
      paths: ['user', 'login', 'play', 'device', 'apply20230001', 'loginAdmin', 'mall', 'distributor', 'giftCard', 'route', 'assessment']
    })
  ]
})
export default store
