<template>
  <div class="ai-exercise-container">
    <base-title-bar-scroll-page
      ref="container"
      title="AI运动识别"
      :showTitleBar="!isApp"
    >
      <div class="ai-exercise">
        <div class="ai-exercise__content">
          <!-- 运动计数显示 - 移到顶部 -->
          <div v-show="!isLoading && !errorMessage && !needPermission" class="ai-exercise__stats">
            <div class="stats-card">
              <div class="stats-icon arm-icon">💪</div>
              <div class="stats-info">
                <div class="stats-label">手臂运动</div>
                <div class="stats-value">{{ armCount }}</div>
              </div>
            </div>
            <div class="stats-card">
              <div class="stats-icon leg-icon">🦵</div>
              <div class="stats-info">
                <div class="stats-label">腿部运动</div>
                <div class="stats-value">{{ legCount }}</div>
              </div>
            </div>
          </div>

          <!-- 视频预览（始终存在） -->
          <div class="video-container">
            <video
              ref="video"
              class="ai-exercise__video"
              :style="{
                visibility: isLoading || errorMessage || needPermission ? 'hidden' : 'visible'
              }"
              playsinline
              autoplay
              muted
            ></video>
          </div>

          <!-- 骨骼点渲染画布 -->
          <div class="canvas-container">
            <canvas
              ref="canvas"
              class="ai-exercise__canvas"
              :style="{
                visibility: isLoading || errorMessage || needPermission ? 'hidden' : 'visible'
              }"
            ></canvas>
          </div>

          <!-- 加载状态 -->
          <div v-show="isLoading" class="ai-exercise__loading">
            <p>{{ loadingMessage }}</p>
          </div>

          <!-- 错误信息 -->
          <div v-show="errorMessage" class="ai-exercise__error">
            <p>{{ errorMessage }}</p>
            <button class="retry-button" @click="retryInitialization">重试</button>
          </div>

          <!-- 权限请求 -->
          <div v-show="needPermission" class="ai-exercise__permission">
            <p>此功能需要访问您的摄像头</p>
            <p class="permission-desc">我们将使用摄像头来识别您的运动姿势，帮助您进行运动计数</p>
            <button class="permission-button" @click="requestPermission">允许使用摄像头</button>
          </div>
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>

<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import * as tf from '@tensorflow/tfjs-core'
import '@tensorflow/tfjs-backend-webgl'
import * as poseDetection from '@tensorflow-models/pose-detection'

// 添加mediaDevices polyfill
const initMediaDevices = () => {
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {}
  }

  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia

      if (!getUserMedia) {
        return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
      }

      return new Promise((resolve, reject) => {
        getUserMedia.call(navigator, constraints, resolve, reject)
      })
    }
  }
}

export default {
  name: 'AiExercise',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      isApp: false,
      detector: null,
      isModelLoaded: false,
      armCount: 0,
      legCount: 0,
      lastKeypoints: null,
      movementThreshold: 30,
      errorMessage: '',
      isLoading: true,
      loadingMessage: '正在加载模型和初始化摄像头...',
      needPermission: true,
      // 检测配置
      detectorConfig: {
        modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING,
        enableSmoothing: true,
        minPoseScore: 0.3,
        modelUrl: 'https://cdn.keihong.tech/前端库/model.json'
      },
      // 摄像头支持状态
      isCameraSupported: false
    }
  },
  async mounted () {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      this.isApp = urlParams.get('isApp') === 'true'

      // 等待DOM完全渲染
      await this.$nextTick()
      // 添加额外的延迟确保DOM完全准备好
      await new Promise(resolve => setTimeout(resolve, 100))

      // 初始化mediaDevices API
      initMediaDevices()
      this.isCameraSupported = true

      // 检查是否在安全上下文中运行
      if (!this.isSecureContext()) {
        const currentProtocol = window.location.protocol
        const currentHost = window.location.hostname

        if (currentProtocol === 'http:') {
          throw new Error(`当前使用的是不安全的HTTP协议(${currentProtocol})，请使用HTTPS协议访问此页面以启用摄像头功能。
如果您是在本地开发环境，请使用localhost或127.0.0.1访问。`)
        } else {
          throw new Error(`当前环境(${currentProtocol}//${currentHost})不是安全上下文，无法使用摄像头功能。
请确保使用HTTPS协议或通过localhost访问。`)
        }
      }

      // 检查是否支持getUserMedia
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('您的浏览器不支持摄像头功能，请使用现代浏览器访问')
      }

      // 先初始化TensorFlow和模型
      this.loadingMessage = '正在设置TensorFlow后端...'
      await tf.setBackend('webgl')
      console.log('TensorFlow后端设置完成:', tf.getBackend())

      this.loadingMessage = '正在加载AI模型...'
      await this.loadModel()
      console.log('AI模型加载成功')

      // 检查摄像头权限状态
      try {
        // 先检查是否有可用的视频输入设备
        const devices = await navigator.mediaDevices.enumerateDevices()
        const videoDevices = devices.filter(device => device.kind === 'videoinput')

        if (videoDevices.length === 0) {
          throw new Error('未检测到摄像头设备，请确保摄像头已正确连接')
        }

        // 检查是否已有标签信息(表示已授权)
        const hasPermission = videoDevices.some(device => device.label)

        if (!hasPermission) {
          // 如果没有权限,直接显示权限请求界面
          console.log('需要请求摄像头权限')
          this.needPermission = true
          this.isLoading = false
          return
        }

        // 如果已有权限,继续初始化
        console.log('已有摄像头权限,继续初始化')
        this.needPermission = false
        await this.initializeSystem()
      } catch (error) {
        console.error('权限检查失败:', error)
        this.handleError(error)
      }
    } catch (error) {
      console.error('初始化失败:', error)
      this.errorMessage = error.message
      this.isLoading = false
    } finally {
      this.hideLoading()
    }
  },
  beforeDestroy () {
    this.cleanup()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },

    cleanup () {
      if (this.$refs.video && this.$refs.video.srcObject) {
        const tracks = this.$refs.video.srcObject.getTracks()
        tracks.forEach(track => track.stop())
      }
    },

    isSecureContext () {
      const isSecure = window.isSecureContext ||
             window.location.protocol === 'https:' ||
             window.location.hostname === 'localhost' ||
             window.location.hostname === '127.0.0.1'

      // 添加调试信息
      console.log('安全上下文检查:', {
        isSecureContext: window.isSecureContext,
        protocol: window.location.protocol,
        hostname: window.location.hostname,
        result: isSecure
      })

      return isSecure
    },

    async requestPermission () {
      try {
        this.needPermission = false
        this.isLoading = true
        this.loadingMessage = '正在请求摄像头权限...'
        this.errorMessage = '' // 清除之前的错误信息

        // 请求摄像头权限
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 640 },
            height: { ideal: 480 },
            frameRate: { ideal: 30 }
          }
        })

        // 获取权限成功后立即停止流
        stream.getTracks().forEach(track => track.stop())

        // 再次检查权限状态
        const devices = await navigator.mediaDevices.enumerateDevices()
        const videoDevices = devices.filter(device => device.kind === 'videoinput')
        const hasPermission = videoDevices.some(device => device.label)

        if (!hasPermission) {
          throw new Error('权限请求未成功,请重试')
        }

        // 继续初始化系统
        this.loadingMessage = '正在初始化系统...'
        await this.initializeSystem()
      } catch (error) {
        console.error('请求权限失败:', error)
        this.handleError(error)
        // 重置到权限请求状态
        this.needPermission = true
        this.isLoading = false
      }
    },

    async initializeSystem () {
      try {
        // 确保DOM已经准备好
        await this.$nextTick()

        // 检查video元素是否存在
        if (!this.$refs.video) {
          console.error('Video元素不存在')
          throw new Error('初始化失败：视频元素未找到，请刷新页面重试')
        }

        this.loadingMessage = '正在初始化摄像头...'
        await this.initCamera()
        console.log('摄像头初始化成功')

        // 重置错误状态
        this.errorMessage = ''
        this.isLoading = false
        this.needPermission = false

        // 开始检测
        this.startDetection()
      } catch (error) {
        console.error('系统初始化失败:', error)
        this.handleError(error)
      }
    },

    handleError (error) {
      this.isLoading = false
      console.error('错误详情:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      })

      // 如果是视频元素未找到的错误,自动重试
      if (error.message.includes('视频元素未找到')) {
        console.log('检测到视频元素未找到错误,自动重试初始化...')
        // 使用setTimeout确保DOM有时间更新
        setTimeout(() => {
          this.retryInitialization()
        }, 500)
        return
      }

      if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        this.errorMessage = '无法访问摄像头，请确保已授予权限'
        this.needPermission = true // 显示权限请求界面
      } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
        this.errorMessage = '未检测到摄像头设备'
      } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
        this.errorMessage = '摄像头被其他应用程序占用'
      } else if (error.message.includes('model')) {
        this.errorMessage = '模型加载失败，请检查网络连接'
      } else {
        this.errorMessage = '初始化失败: ' + error.message
      }
    },

    async retryInitialization () {
      try {
        // 重置所有状态
        this.errorMessage = ''
        this.isLoading = true
        this.loadingMessage = '正在重新初始化...'
        this.cleanup()

        // 确保DOM完全准备好
        await new Promise(resolve => setTimeout(resolve, 100))
        await this.$nextTick()

        // 重新初始化mediaDevices
        initMediaDevices()

        // 检查摄像头权限
        try {
          const devices = await navigator.mediaDevices.enumerateDevices()
          const videoDevices = devices.filter(device => device.kind === 'videoinput')
          const hasPermission = videoDevices.some(device => device.label)

          if (!hasPermission) {
            console.log('需要请求摄像头权限')
            this.needPermission = true
            this.isLoading = false
            return
          }

          // 如果已有权限,继续初始化
          console.log('已有摄像头权限,继续初始化')
          this.needPermission = false
          this.isCameraSupported = true
          await this.initializeSystem()
        } catch (error) {
          console.error('权限检查失败:', error)
          this.handleError(error)
        }
      } catch (error) {
        console.error('重试初始化失败:', error)
        this.handleError(error)
      }
    },

    async loadModel () {
      try {
        this.detector = await poseDetection.createDetector(
          poseDetection.SupportedModels.MoveNet,
          this.detectorConfig
        )
        this.isModelLoaded = true
      } catch (error) {
        console.error('模型加载失败:', error)
        throw new Error('模型加载失败，请检查网络连接')
      }
    },

    async initCamera () {
      try {
        // 先检查是否有可用的视频输入设备
        const devices = await navigator.mediaDevices.enumerateDevices()
        const videoDevices = devices.filter(device => device.kind === 'videoinput')

        console.log('检测到的视频设备:', videoDevices.map(device => ({
          deviceId: device.deviceId,
          label: device.label || '未命名设备',
          groupId: device.groupId
        })))

        if (videoDevices.length === 0) {
          throw new Error('未检测到摄像头设备，请确保摄像头已正确连接')
        }

        // 尝试使用第一个可用的摄像头
        const firstDevice = videoDevices[0]
        console.log('尝试使用摄像头:', firstDevice.label || '未命名设备')

        const constraints = {
          video: {
            deviceId: firstDevice.deviceId ? { exact: firstDevice.deviceId } : undefined,
            width: { ideal: 640 },
            height: { ideal: 480 },
            frameRate: { ideal: 30 }
          },
          audio: false
        }

        console.log('使用以下配置请求摄像头:', constraints)

        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        console.log('成功获取视频流')

        const videoElement = this.$refs.video
        if (!videoElement) {
          throw new Error('视频元素未找到')
        }

        // 设置视频源
        videoElement.srcObject = stream
        videoElement.style.transform = 'scaleX(-1)' // 镜像显示

        // 等待视频元数据加载和播放就绪
        await new Promise((resolve, reject) => {
          const maxAttempts = 3 // 最大重试次数
          let attempts = 0

          const tryLoadVideo = () => {
            attempts++
            console.log(`尝试加载视频 (第${attempts}次)`)

            const timeout = setTimeout(() => {
              if (attempts >= maxAttempts) {
                cleanup()
                reject(new Error('视频元数据加载超时'))
              } else {
                console.log('重试加载视频...')
                cleanup()
                tryLoadVideo()
              }
            }, 15000) // 延长到15秒

            const cleanup = () => {
              videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
              videoElement.removeEventListener('loadeddata', handleLoadedData)
              videoElement.removeEventListener('canplay', handleCanPlay)
              videoElement.removeEventListener('error', handleError)
              clearTimeout(timeout)
            }

            const handleLoadedMetadata = () => {
              console.log('视频元数据加载完成')
            }

            const handleLoadedData = () => {
              console.log('视频数据加载完成')
            }

            const handleCanPlay = () => {
              console.log('视频可以播放')
              cleanup()
              resolve()
            }

            const handleError = (error) => {
              console.error('视频加载错误:', error)
              cleanup()
              if (attempts >= maxAttempts) {
                reject(new Error(`视频加载失败: ${error.message || '未知错误'}`))
              } else {
                tryLoadVideo()
              }
            }

            videoElement.addEventListener('loadedmetadata', handleLoadedMetadata)
            videoElement.addEventListener('loadeddata', handleLoadedData)
            videoElement.addEventListener('canplay', handleCanPlay)
            videoElement.addEventListener('error', handleError)
          }

          tryLoadVideo()
        })

        // 尝试播放视频
        try {
          await videoElement.play()
          console.log('视频开始播放')
        } catch (error) {
          console.error('视频播放失败:', error)
          throw new Error(`视频播放失败: ${error.message}`)
        }

        // 设置canvas尺寸
        const canvas = this.$refs.canvas
        if (!canvas) {
          throw new Error('Canvas元素未找到')
        }

        canvas.width = videoElement.videoWidth || 640
        canvas.height = videoElement.videoHeight || 480

        console.log('摄像头初始化完成 - 分辨率:', canvas.width, 'x', canvas.height)
      } catch (error) {
        console.error('摄像头初始化失败:', error)

        // 检查是否是权限问题
        if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
          this.needPermission = true
          throw new Error('无法访问摄像头，请在Chrome设置中允许网站访问摄像头\n设置路径：设置 -> 隐私和安全 -> 网站设置 -> 摄像头')
        }

        // 检查是否是设备被占用
        if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
          throw new Error('摄像头可能被其他程序占用，请关闭其他使用摄像头的程序后重试')
        }

        // 其他错误
        throw new Error(`摄像头初始化失败: ${error.message}\n请确保摄像头已正确连接并且驱动程序已安装`)
      }
    },

    async detectPose () {
      if (!this.detector || !this.$refs.video || !this.isModelLoaded) return

      try {
        // 进行姿态检测
        const poses = await this.detector.estimatePoses(this.$refs.video, {
          flipHorizontal: false
        })

        if (poses.length > 0) {
          const keypoints = poses[0].keypoints

          // 绘制骨骼点
          this.drawKeypoints(keypoints)

          // 检测运动
          if (poses[0].score > this.detectorConfig.minPoseScore) {
            this.detectMovement(keypoints)
          }

          // 更新上一帧关键点
          this.lastKeypoints = keypoints
        }

        // 继续下一帧检测
        requestAnimationFrame(() => this.detectPose())
      } catch (error) {
        console.error('姿态检测错误:', error)
        this.errorMessage = '姿态检测出错，请刷新页面重试'
      }
    },

    drawKeypoints (keypoints) {
      const ctx = this.$refs.canvas.getContext('2d')
      const { width, height } = this.$refs.canvas

      // 清除画布
      ctx.clearRect(0, 0, width, height)

      // 应用镜像变换
      ctx.save()
      ctx.scale(-1, 1)
      ctx.translate(-width, 0)

      // 绘制关键点
      keypoints.forEach(point => {
        if (point.score > this.detectorConfig.minPoseScore) {
          ctx.beginPath()
          ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI)
          ctx.fillStyle = 'red'
          ctx.fill()
        }
      })

      // 绘制骨骼连线
      this.drawSkeleton(keypoints, ctx)

      // 恢复画布状态
      ctx.restore()
    },

    drawSkeleton (keypoints, ctx) {
      const connections = [
        ['nose', 'left_eye'], ['nose', 'right_eye'],
        ['left_eye', 'left_ear'], ['right_eye', 'right_ear'],
        ['left_shoulder', 'right_shoulder'],
        ['left_shoulder', 'left_elbow'],
        ['right_shoulder', 'right_elbow'],
        ['left_elbow', 'left_wrist'],
        ['right_elbow', 'right_wrist'],
        ['left_shoulder', 'left_hip'],
        ['right_shoulder', 'right_hip'],
        ['left_hip', 'right_hip'],
        ['left_hip', 'left_knee'],
        ['right_hip', 'right_knee'],
        ['left_knee', 'left_ankle'],
        ['right_knee', 'right_ankle']
      ]

      ctx.strokeStyle = 'white'
      ctx.lineWidth = 2

      connections.forEach(([p1, p2]) => {
        const point1 = keypoints.find(p => p.name === p1)
        const point2 = keypoints.find(p => p.name === p2)

        if (point1 && point2 &&
            point1.score > this.detectorConfig.minPoseScore &&
            point2.score > this.detectorConfig.minPoseScore) {
          ctx.beginPath()
          ctx.moveTo(point1.x, point1.y)
          ctx.lineTo(point2.x, point2.y)
          ctx.stroke()
        }
      })
    },

    detectMovement (currentKeypoints) {
      if (!this.lastKeypoints) return

      // 检测手臂运动
      this.detectArmMovement(currentKeypoints)

      // 检测腿部运动
      this.detectLegMovement(currentKeypoints)
    },

    detectArmMovement (currentKeypoints) {
      const leftWrist = currentKeypoints.find(p => p.name === 'left_wrist')
      const rightWrist = currentKeypoints.find(p => p.name === 'right_wrist')
      const lastLeftWrist = this.lastKeypoints.find(p => p.name === 'left_wrist')
      const lastRightWrist = this.lastKeypoints.find(p => p.name === 'right_wrist')

      if (leftWrist && lastLeftWrist &&
          leftWrist.score > this.detectorConfig.minPoseScore &&
          lastLeftWrist.score > this.detectorConfig.minPoseScore) {
        const distance = Math.sqrt(
          Math.pow(leftWrist.y - lastLeftWrist.y, 2) +
          Math.pow(leftWrist.x - lastLeftWrist.x, 2)
        )
        if (distance > this.movementThreshold) {
          this.armCount++
        }
      }

      if (rightWrist && lastRightWrist &&
          rightWrist.score > this.detectorConfig.minPoseScore &&
          lastRightWrist.score > this.detectorConfig.minPoseScore) {
        const distance = Math.sqrt(
          Math.pow(rightWrist.y - lastRightWrist.y, 2) +
          Math.pow(rightWrist.x - lastRightWrist.x, 2)
        )
        if (distance > this.movementThreshold) {
          this.armCount++
        }
      }
    },

    detectLegMovement (currentKeypoints) {
      const leftAnkle = currentKeypoints.find(p => p.name === 'left_ankle')
      const rightAnkle = currentKeypoints.find(p => p.name === 'right_ankle')
      const lastLeftAnkle = this.lastKeypoints.find(p => p.name === 'left_ankle')
      const lastRightAnkle = this.lastKeypoints.find(p => p.name === 'right_ankle')

      if (leftAnkle && lastLeftAnkle &&
          leftAnkle.score > this.detectorConfig.minPoseScore &&
          lastLeftAnkle.score > this.detectorConfig.minPoseScore) {
        const distance = Math.sqrt(
          Math.pow(leftAnkle.y - lastLeftAnkle.y, 2) +
          Math.pow(leftAnkle.x - lastLeftAnkle.x, 2)
        )
        if (distance > this.movementThreshold) {
          this.legCount++
        }
      }

      if (rightAnkle && lastRightAnkle &&
          rightAnkle.score > this.detectorConfig.minPoseScore &&
          lastRightAnkle.score > this.detectorConfig.minPoseScore) {
        const distance = Math.sqrt(
          Math.pow(rightAnkle.y - lastRightAnkle.y, 2) +
          Math.pow(rightAnkle.x - lastRightAnkle.x, 2)
        )
        if (distance > this.movementThreshold) {
          this.legCount++
        }
      }
    },

    startDetection () {
      if (this.isModelLoaded) {
        requestAnimationFrame(() => this.detectPose())
      }
    }
  }
}
</script>

<style scoped>
.ai-exercise-container {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
}

.ai-exercise {
  padding: 20px;
  background-color: #2A2A2A !important;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0;
  width: 100%;
}

.ai-exercise__content {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  flex: 1;
  overflow: hidden;
}

.video-container,
.canvas-container {
  position: relative;
  width: 100%;
  height: 60vh;
  margin-top: 20px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #2A2A2A;
  box-sizing: border-box;
}

/* 运动统计样式 */
.ai-exercise__stats {
  position: absolute;
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  padding: 15px;
  z-index: 20;
  box-sizing: border-box;
  top: 5px; /* 调整为更小的值 */
  left: 50%;
  transform: translateX(-50%);
}

.stats-card {
  background: rgba(255, 255, 255, 0.5); /* 降低背景透明度 */
  border-radius: 15px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* 调整阴影透明度 */
  transition: transform 0.3s ease;
  flex: 1;
  max-width: 200px;
  box-sizing: border-box;
  backdrop-filter: blur(8px); /* 增加模糊度来提升可读性 */
}

.stats-card:hover {
  transform: translateY(-2px);
}

.stats-icon {
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}

.stats-info {
  flex: 1;
}

.stats-label {
  font-size: 14px;
  color: #333; /* 加深文字颜色 */
  margin-bottom: 4px;
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.5); /* 添加文字阴影提升可读性 */
}

.stats-value {
  font-size: 24px;
  font-weight: bold;
  color: #1976D2; /* 调整为更深的蓝色 */
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.5); /* 添加文字阴影提升可读性 */
}

.ai-exercise__loading,
.ai-exercise__error,
.ai-exercise__permission {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  color: #fff;
  font-size: 16px;
  text-align: center;
  background: rgba(42, 42, 42, 0.9);
  z-index: 10;
  padding: 20px;
  border-radius: 15px;
  backdrop-filter: blur(5px);
  box-sizing: border-box;
}

.ai-exercise__error {
  color: #ff6b6b;
}

.permission-desc {
  margin: 10px 0 20px;
  color: #999;
  font-size: 14px;
  line-height: 1.5;
}

.permission-button,
.retry-button {
  margin-top: 20px;
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  background: #4CAF50;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(76, 175, 80, 0.3);
}

.retry-button {
  background: #2196F3;
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.3);
}

.permission-button:hover,
.retry-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(76, 175, 80, 0.4);
}

.retry-button:hover {
  box-shadow: 0 4px 12px rgba(33, 150, 243, 0.4);
}

.ai-exercise__video,
.ai-exercise__canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.ai-exercise__canvas {
  pointer-events: none;
}

/* 响应式调整 */
@media screen and (max-width: 768px) {
  .ai-exercise {
    padding: 10px;
  }

  .ai-exercise__content {
    padding: 0 10px;
  }

  .ai-exercise__stats {
    padding: 10px 5px;
    gap: 10px;
    top: 10px; /* 移动端稍微往下一点 */
    width: calc(100% - 20px);
  }

  .stats-card {
    padding: 10px;
    font-size: 14px; /* 减小字体大小 */
  }

  .stats-icon {
    font-size: 20px; /* 减小图标大小 */
    width: 35px;
    height: 35px;
  }

  .stats-value {
    font-size: 20px; /* 减小数值字体大小 */
  }

  .stats-label {
    font-size: 12px; /* 减小标签字体大小 */
  }

  .video-container,
  .canvas-container {
    height: 50vh;
    margin-top: 10px;
  }

  .ai-exercise__loading,
  .ai-exercise__error,
  .ai-exercise__permission {
    height: 50vh;
    padding: 15px;
  }
}

/* 全局样式修复 */
:deep(.base-title-bar-scroll-page) {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}
</style>
