<template>
  <div>
    <my-nav-bar v-if="!isApp" :title="'筛查&评估记录'"/>
    <container-list :empty="empty" :empty-desc="'暂无训练方案'" :loading="loading&&(datas.length===0)">
      <div>
        <van-pull-refresh v-model="refreshing" @refresh="loadDatas">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="httpDatas"
          >
            <div v-for="(item,index) in datas" :key="index">
              <div style="padding: 16px" @click="onItemClick(item)">
                <div>
                </div>
                <div style="font-size: 18px;color: #606060">发送时间：{{ item.gmtCreate }}</div>
                <div style="font-size: 28px;margin-top: 8px">{{ item.plan.planTitle.replace('(无训练方案)', '') }}</div>
                <div style="font-size: 18px;color: #606060;margin-top: 16px">医生：{{ item.doctorName }}</div>
              </div>
              <van-divider/>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </container-list>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import ContainerList from '../../components/ContainerList'
import PlanDao from '../../network/PlanDao'
import DateUtils from '../../../../utils/DateUtils'

export default {
  name: 'ScreeningListPage',
  components: {
    ContainerList,
    MyNavBar
  },
  data () {
    return {
      isApp: false,
      empty: true,
      loading: true,
      finished: false,
      refreshing: false,
      datas: [],
      loadTemplate: false
    }
  },
  mounted () {
    this.isApp = this.$route.query.wxapp
    this.loadDatas()
  },
  methods: {
    onItemClick (item) {
      this.$store.commit('play/setPlan', item)
      // this.$router.push('/PlanDetailPage')
      this.$store.commit('assessment/setJsonString', item.assess)
      this.$router.push({
        path: '/AssessmentSpine?isReadAssess=true&from=PatientWeb&assessmentDate=' + item.gmtCreate
      })
    },
    async httpDatas () {
      // 优先获取URL中的phone参数
      const phoneFromUrl = this.$route.query.phone
      const phone = this.loadTemplate ? '17525253636' : (phoneFromUrl || this.$store.state.user.profile.phone)
      const datas = await PlanDao.loadList(phone)
      const screeningDatas = []
      for (const e of datas) {
        e.plan = JSON.parse(e.plan)
        console.log('e.plan', e.plan)
        e.gmtCreate = DateUtils.formate(e.gmtCreate, 'yyyy-MM-DD HH:mm:ss')
        console.log(e.plan)

        const flag = (e.plan.planTitle.includes('筛查') && e.plan.planCourseBeans.length === 0) || e.assessType === 3
        console.log('flag', flag)
        console.log('e.assessType === 3', e.assessType)
        if (flag) {
          screeningDatas.push(e)
          if (this.loadTemplate) {
            // e.plan.planTitle 添加前缀
            e.plan.planTitle = '【演示】' + e.plan.planTitle
            break
          }
        }
      }

      this.datas = screeningDatas
      if (this.loadTemplate) {
        this.loadTemplate = false
      }
    },
    async loadDatas () {
      this.loading = true
      this.refreshing = true
      await this.httpDatas()
      if (this.datas.length === 0) {
        this.loadTemplate = true
        await this.httpDatas()
      }
      console.log('数据请求结果', this.datas)
      if (this.datas.length) {
        this.empty = false
      } else {
        this.empty = true
      }
      this.finished = true
      this.loading = false
      this.refreshing = false
    }
  }
}
</script>

<style scoped>

</style>
