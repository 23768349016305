<template>
  <div>
    <base-title-bar-scroll-page ref="container" :show-title-bar="false" title="检查数据">

    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'

export default {
  name: 'CheckHospital',
  components: { BaseTitleBarScrollPage },
  data () {
    return {}
  },
  mounted () {
    // 从小程序跳转
    // webUrl.value = `https://doucan.jianerkanggz.cn/#/
    // CheckHospital?from=wxapp&hospitalId=${accountStore.ext.hospitalId}&phone=${phone.value}&openid=${accountStore.miniOpenId}`
    // CheckHospital?from=wxapp&hospitalId=${accountStore.ext.hospitalId}&phone=13610164743&openid=o0YpI4zQ7bWEct3B3fmSgX2ravoE
    if (this.$route.query.openid) {
      this.$store.commit('setIsWxapp', true)
      this.$store.commit('setWxAppOpenId', this.$route.query.openid)
      console.log('openid', this.$route.query.openid)
      // this.test = this.$store.state.wxAppOpenId
    }
    if (this.$route.query.hospitalId) {
      console.log('hospitalId', this.$route.query.hospitalId)
      console.log('phone', this.$route.query.phone)
      // 此处的hospitalId是医院的id，而setQrCodeHospital是医院的名称，这里不作持久化
      // this.$store.commit('user/setQrCodeHospital', this.$route.query.hospitalId)
    }
    if (this.$route.query.hospitalId === '56') {
      // 宏乾
      // 重定向到 /hongqian/home
      this.$store.commit('user/setQrCodeHospital', '宏乾健康')
      this.$store.commit('user/setQrCodeHospitalId', 56)
      console.log('登录用户', this.$store.state.user)

      // 获取当前页面的完整查询参数
      const currentQuery = this.$route.query
      // 带上原有参数跳转
      this.$router.replace({
        path: '/hongqian/home',
        query: currentQuery
      })
    } else {
      // 其他医院
      // 重定向到 /healthstation/home
      // 获取当前页面的完整查询参数
      const currentQuery = this.$route.query
      // 带上原有参数跳转
      this.$router.replace({
        path: '/healthstation/home',
        query: currentQuery
      })
    }
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
