import axios from 'axios'

/**
 * 用户数据访问对象
 */
const UserDao = {
  /**
   * 转换性别显示
   * @param {number} sex 性别代码(1:男, 2:女)
   * @returns {string} 性别显示文本
   */
  _formatGender (sex) {
    switch (sex) {
      case 1:
        return '男'
      case 2:
        return '女'
      default:
        return ''
    }
  },

  /**
   * 获取用户信息
   * @param {string} userId 用户ID
   * @returns {Promise<Object|null>} 用户信息，失败返回null
   */
  async getUserInfo (userId) {
    const url = `/user/user_id/${userId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)

    const { code, data } = resp
    if (code !== 200 || !data) {
      return null
    }

    // 转换性别显示
    return {
      ...data,
      gender: this._formatGender(data.sex)
    }
  },

  /**
   * 更新用户信息
   * @param {string} userId 用户ID
   * @param {Object} userData 用户数据
   * @returns {Promise<Object|null>} 更新后的用户信息，失败返回null
   */
  async updateUserInfo (userId, userData) {
    const url = `/user/user_id/${userId}`
    const httpResp = await axios.put(url, userData)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)

    const { code, data } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}

export default UserDao
