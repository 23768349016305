<template>
  <div>
    <base-title-bar-scroll-page ref="container" :showTitleBar="!isApp" title="患者笔记汇总" @scroll="handleScroll">
      <div>
        <!-- 操作按钮 -->
        <div class="action-buttons">
          <div class="action-button" @click="handleFilter">
            <div class="icon">
              <van-icon color="#2c8be9" name="filter-o" size="24" />
            </div>
            <span class="text">筛选</span>
          </div>
          <div class="action-button" @click="handleExport">
            <div class="icon">
              <van-icon color="#2c8be9" name="down" size="24" />
            </div>
            <span class="text">导出</span>
          </div>
          <div class="action-button" @click="handleStats">
            <div class="icon">
              <van-icon color="#2c8be9" name="chart-trending-o" size="24" />
            </div>
            <span class="text">统计</span>
          </div>
        </div>

        <!-- 笔记列表 -->
        <div class="note-list">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多数据了" loading-text="加载中..."
            @load="loadMore">
            <patient-note-item v-for="(note, index) in noteList" :key="index" :note="note"
              :show-date-divider="shouldShowDateDivider(note, index)" :is-demo-note="isDemoNote(note)"
              @media-click="handleMediaClick" />
          </van-list>
        </div>
      </div>
    </base-title-bar-scroll-page>

    <!-- 视频播放器 -->
    <div v-if="showVideoPlayer" class="video-player-container" @click="handleContainerClick">
      <div class="video-close" @click="handleVideoClose">
        <i class="video-close-icon"></i>
      </div>
      <video-player ref="videoPlayer" :options="playerOptions" :playsinline="true" class="video-player vjs-custom-skin"
        @pause="handleVideoClose" @play="onPlayerPlay" @click.stop />
    </div>
  </div>
</template>

<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { Icon, ImagePreview, List, Toast } from 'vant'
import VideoPlayer from '@/common-components/VideoPlayer/VideoPlayer.vue'
import 'video.js/dist/video-js.css'
import PatientNoteDao from '@/h5/doucan/network/PatientNoteDao'
import PatientNoteItem from '@/h5/doucan/components/PatientNoteItem.vue'

export default {
  name: 'patient_note_summary',
  components: {
    BaseTitleBarScrollPage,
    VideoPlayer,
    PatientNoteItem,
    'van-list': List,
    'van-icon': Icon
  },
  data () {
    return {
      hospitalId: '',
      noteList: [],
      isApp: false,
      showVideoPlayer: false,
      currentVideoUrl: '',
      currentVideoIndex: 0,
      currentAttachments: [],
      player: null,
      patientName: '',
      // 分页相关数据
      pageSize: 10,
      currentPage: 1,
      total: 0,
      loading: false,
      finished: false,
      playerOptions: {
        autoplay: false,
        muted: false,
        loop: false,
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        poster: '',
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true
        }
      }
    }
  },
  mounted () {
    // 获取URL参数
    this.hospitalId = this.$route.query.hospital_id
    this.isApp = this.$route.query.app === 'true'
    console.log('医院ID:', this.hospitalId)

    // 获取患者姓名
    this.patientName = this.$parent.patientInfo?.patientName || ''

    // 获取token参数 到 store.state.user.profile.token
    const token = this.$route.query.token
    console.log('token:', token)
    // 设置store.state.user.profile.token
    this.$store.state.user.profile.token = token

    // 初始化数据
    this.fetchNoteList()

    // 初始化视频播放器引用
    this.$nextTick(() => {
      if (this.$refs.videoPlayer) {
        this.player = this.$refs.videoPlayer.player
      }
    })
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    /**
     * 处理媒体点击
     * @param {Object} item 当前点击的媒体项
     * @param {Array} attachments 当前记录的所有附件
     * @param {Number} index 当前点击的索引
     */
    handleMediaClick ({ item, attachments, index }) {
      if (item.type === 'video') {
        this.showVideo(item, index)
      } else {
        this.showImagePreview(attachments, index)
      }
    },

    /**
     * 从视频获取第一帧作为封面
     * @param {string} videoUrl 视频URL
     * @returns {Promise<string>} 返回第一帧的 base64 数据
     */
    getVideoFirstFrame (videoUrl) {
      return new Promise((resolve, reject) => {
        console.log('开始获取视频封面:', videoUrl)
        const video = document.createElement('video')
        video.crossOrigin = 'anonymous'
        video.src = videoUrl
        video.preload = 'auto'

        // 设置超时
        const timeout = setTimeout(() => {
          console.warn('获取视频封面超时:', videoUrl)
          video.remove()
          reject(new Error('获取视频封面超时'))
        }, 15000) // 增加到15秒

        // 视频加载失败时的处理
        video.onerror = (e) => {
          console.warn('视频加载失败:', videoUrl, e.target.error)
          clearTimeout(timeout)
          video.remove()
          reject(new Error(`视频加载失败: ${e.target.error?.message || '未知错误'}`))
        }

        // 元数据加载完成后设置时间
        video.onloadedmetadata = () => {
          console.log('视频元数据加载完成:', videoUrl, '时长:', video.duration)
          video.currentTime = 0.1
        }

        // 视频可以播放时的处理
        video.oncanplay = () => {
          console.log('视频可以播放:', videoUrl)
        }

        // 时间设置完成后的事件
        video.onseeked = () => {
          console.log('视频seek完成,准备获取封面:', videoUrl)
          try {
            const canvas = document.createElement('canvas')
            canvas.width = video.videoWidth
            canvas.height = video.videoHeight
            console.log('视频尺寸:', canvas.width, 'x', canvas.height)

            const ctx = canvas.getContext('2d')
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
            const dataUrl = canvas.toDataURL('image/jpeg')
            console.log('成功获取视频封面:', videoUrl)

            clearTimeout(timeout)
            video.remove()
            resolve(dataUrl)
          } catch (e) {
            console.warn('获取视频封面失败:', videoUrl, e)
            clearTimeout(timeout)
            video.remove()
            reject(e)
          }
        }

        // 视频加载进度
        video.onprogress = (e) => {
          if (video.buffered.length > 0) {
            console.log('视频加载进度:', videoUrl,
              Math.round(video.buffered.end(0) / video.duration * 100) + '%')
          }
        }
      })
    },

    /**
     * 处理视频容器点击
     * @param {Event} event 点击事件对象
     */
    handleContainerClick (event) {
      // 如果点击的是容器本身(而不是视频播放器),则关闭播放器
      if (event.target === event.currentTarget) {
        this.handleVideoClose()
      }
    },

    /**
     * 关闭视频播放器
     */
    handleVideoClose () {
      if (this.player) {
        try {
          this.player.pause()
          this.player.currentTime(0)
        } catch (e) {
          console.warn('视频暂停失败:', e)
        }
      }
      this.showVideoPlayer = false
      this.currentVideoUrl = ''
    },

    /**
     * 处理视频播放
     */
    onPlayerPlay () {
      // 用户手动点击播放时的处理
      if (this.player) {
        try {
          this.player.play()
        } catch (e) {
          console.warn('视频播放失败:', e)
        }
      }
    },

    /**
     * 处理笔记列表数据,为视频获取封面图
     * @param {Array} notes 笔记列表数据
     */
    async processNoteList (notes) {
      notes = notes.filter(note => note !== null && note !== undefined)
      for (const note of notes) {
        if (note.attachments && note.attachments.length) {
          for (const attachment of note.attachments) {
            if (attachment.type === 'video' && !attachment.thumbnail) {
              try {
                // 获取视频第一帧作为封面
                attachment.thumbnail = await this.getVideoFirstFrame(attachment.url)
              } catch (e) {
                console.warn('获取视频封面失败:', e)
                // 设置一个默认的封面图
                attachment.thumbnail = 'https://cdn.keihong.tech/img/video_default_cover.png'
              }
            }
          }
        }
      }
      return notes
    },

    /**
     * 处理滚动事件
     * @param {Event} event 滚动事件对象
     */
    handleScroll (event) {
      const {
        scrollHeight,
        scrollTop,
        clientHeight
      } = event.target

      // 距离底部30px时加载更多
      if (scrollHeight - scrollTop - clientHeight < 30 && !this.loading && !this.finished) {
        console.log('触发加载更多')
        this.loadMore()
      }
    },

    /**
     * 从服务器获取笔记列表数据
     */
    async fetchNoteList () {
      try {
        // 重置状态
        this.loading = true
        this.finished = false
        this.currentPage = 1
        this.noteList = []

        console.log('初始化加载笔记列表 - 页码:', this.currentPage, '每页数量:', this.pageSize)

        // 构建查询参数
        const params = {
          hospitalId: this.hospitalId,
          page: this.currentPage,
          size: this.pageSize
        }

        // 调用API获取数据
        const response = await PatientNoteDao.getHospitalNoteList(params)
        if (!response || !response.content || response.content.length === 0) {
          this.finished = true
          return
        }

        console.log('获取到的初始笔记数据:', response)

        // 设置总数
        this.total = response.totalElements || 0

        // 处理笔记列表数据,为视频获取封面图
        const processedNotes = await this.processNoteList(response.content)
        console.log('处理后的初始笔记数据:', processedNotes)

        // 设置数据
        this.noteList = processedNotes

        // 判断是否加载完成
        this.finished = this.noteList.length >= this.total
        console.log('数据加载状态 - 当前数量:', this.noteList.length, '总条数:', this.total, '是否完成:', this.finished)
      } catch (error) {
        console.error('获取笔记列表失败:', error)
        this.finished = true
      } finally {
        this.loading = false
        this.hideLoading()
      }
    },

    /**
     * 加载更多数据
     */
    async loadMore () {
      // 如果已经加载完成或正在加载，直接返回
      if (this.finished || this.loading) {
        this.loading = false
        return
      }

      try {
        // 设置加载状态
        this.loading = true
        this.currentPage++

        console.log('加载更多数据 - 当前页码:', this.currentPage, '每页数量:', this.pageSize)

        // 构建查询参数
        const params = {
          hospitalId: this.hospitalId,
          page: this.currentPage,
          size: this.pageSize
        }

        // 调用API获取数据
        const response = await PatientNoteDao.getHospitalNoteList(params)
        if (!response || !response.content || response.content.length === 0) {
          this.finished = true
          return
        }

        console.log('获取到的笔记数据:', response)

        // 处理笔记列表数据,为视频获取封面图
        const processedNotes = await this.processNoteList(response.content)
        console.log('处理后的笔记数据:', processedNotes)

        // 追加数据
        this.noteList = [...this.noteList, ...processedNotes]
        console.log('当前列表总数据:', this.noteList.length)

        // 判断是否加载完成
        this.finished = this.noteList.length >= this.total
        console.log('数据加载状态 - 当前数量:', this.noteList.length, '总条数:', this.total, '是否完成:', this.finished)
      } catch (error) {
        console.error('加载更多数据失败:', error)
        this.finished = true
      } finally {
        this.loading = false
      }
    },

    /**
     * 处理筛选按钮点击
     */
    handleFilter () {
      Toast('筛选功能开发中')
    },

    /**
     * 处理导出按钮点击
     */
    handleExport () {
      Toast('导出功能开发中')
    },

    /**
     * 处理统计按钮点击
     */
    handleStats () {
      Toast('统计功能开发中')
    },

    /**
     * 获取年份
     * @param {string} date 日期字符串
     * @returns {string} 年份
     */
    getYear (date) {
      return new Date(date).getFullYear()
    },

    /**
     * 获取月份
     * @param {string} date 日期字符串
     * @returns {number} 月份
     */
    getMonth (date) {
      return new Date(date).getMonth() + 1
    },

    /**
     * 格式化日期时间
     * @param {string} date 日期字符串
     * @returns {string} 格式化后的日期时间
     */
    formatDateTime (date) {
      if (!date) return ''
      const d = new Date(date)
      const year = d.getFullYear()
      const month = String(d.getMonth() + 1).padStart(2, '0')
      const day = String(d.getDate()).padStart(2, '0')
      const hours = String(d.getHours()).padStart(2, '0')
      const minutes = String(d.getMinutes()).padStart(2, '0')
      return `${year}-${month}-${day} ${hours}:${minutes}`
    },

    /**
     * 判断是否显示日期分隔符
     * @param {Object} note 当前笔记
     * @param {number} index 当前索引
     * @returns {boolean} 是否显示
     */
    shouldShowDateDivider (note, index) {
      if (index === 0) return true
      const currentDate = new Date(note.recordDate)
      const prevDate = new Date(this.noteList[index - 1].recordDate)
      return (
        currentDate.getFullYear() !== prevDate.getFullYear() ||
        currentDate.getMonth() !== prevDate.getMonth()
      )
    },

    /**
     * 判断是否为演示笔记
     * @param {Object} note 笔记对象
     * @returns {boolean} 是否为演示笔记
     */
    isDemoNote (note) {
      if (!note) return false
      return note.isDemo === true
    },

    /**
     * 显示视频
     */
    async showVideo (item, index) {
      // 先关闭之前的视频播放器
      if (this.showVideoPlayer && this.player) {
        try {
          this.player.pause()
          this.player.currentTime(0)
        } catch (e) {
          console.warn('暂停上一个视频失败:', e)
        }
      }

      try {
        // 尝试获取视频第一帧作为封面
        const poster = await this.getVideoFirstFrame(item.url)

        // 设置播放器
        this.playerOptions = {
          ...this.playerOptions,
          sources: [{
            type: 'video/mp4',
            src: item.url
          }],
          poster: poster || item.thumbnail || ''
        }

        this.currentVideoIndex = index
        this.showVideoPlayer = true

        // 等待DOM更新后初始化播放器
        this.$nextTick(() => {
          if (this.$refs.videoPlayer) {
            this.player = this.$refs.videoPlayer.player
            if (this.player) {
              this.player.src({
                type: 'video/mp4',
                src: item.url
              })
              this.player.load()
            }
          }
        })
      } catch (e) {
        console.error('显示视频失败:', e)
        Toast('视频播放失败,请稍后重试')
      }
    },

    /**
     * 显示图片预览
     */
    showImagePreview (attachments, index) {
      // 收集所有媒体项
      const mediaItems = attachments.map(attachment => {
        if (attachment.type === 'video') {
          return {
            type: 'video',
            url: attachment.thumbnail,
            videoUrl: attachment.url
          }
        }
        return {
          type: 'image',
          url: attachment.url
        }
      })

      // 使用 vant 的 ImagePreview
      const instance = ImagePreview({
        images: mediaItems.map(item => item.url),
        startPosition: index,
        closeable: true,
        onClose: () => {
          console.log('预览关闭')
        },
        onChange: (idx) => {
          const currentItem = mediaItems[idx]
          if (currentItem.type === 'video') {
            this.showVideo({ url: currentItem.videoUrl }, idx)
            instance.close()
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.action-buttons {
  display: flex;
  justify-content: space-around;
  padding: 15px;
  background: #fff;
  margin-bottom: 10px;
}

.action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.action-button .icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.action-button .text {
  font-size: 12px;
  color: #666;
}

.note-list {
  padding: 10px;
}

.note-item {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}

.note-item.is-demo::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 20px 20px 0;
  border-style: solid;
  border-color: #ff9800 #fff;
}

.date-divider {
  margin-left: 16px;
  padding: 24px 0 12px;
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
}

.date-divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background: #eee;
  margin-left: 12px;
}

.date-divider .year {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin-right: 8px;
  position: relative;
  padding-right: 12px;
}

.date-divider .year::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 12px;
  background: #ddd;
}

.date-divider .month {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  background: #f5f7fa;
  padding: 4px 12px;
  border-radius: 20px;
  min-width: 64px;
  text-align: center;
}

.note-content-wrapper {
  padding: 15px;
}

.note-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.note-header-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.note-date-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.note-date {
  font-size: 14px;
  color: #999;
  margin-left: 4px;
}

.patient-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-right: 12px;
}

.demo-tag {
  background: #ff9800;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 8px;
}

.note-doctor {
  display: flex;
  align-items: center;
  background: #f5f7fa;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #666;
  line-height: 1.2;
}

.note-doctor .doctor-icon {
  margin-right: 4px;
}

.note-content {
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 10px;
}

.attachment-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-top: 10px;
}

.attachment-item {
  position: relative;
  padding-bottom: 100%;
  cursor: pointer;
}

.attachment-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.attachment-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.attachment-video {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-play {
  border-style: solid;
  border-width: 10px 0 10px 16px;
  border-color: transparent transparent transparent #fff;
  margin-left: 4px;
}

.video-player-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1001;
}

.video-close-icon {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-close-icon::before,
.video-close-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
}

.video-close-icon::before {
  transform: rotate(45deg);
}

.video-close-icon::after {
  transform: rotate(-45deg);
}

.video-player {
  width: 100%;
  max-width: 800px;
}

/* 加载状态样式 */
.loading-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.loading-text {
  margin-top: 10px;
  font-size: 14px;
  color: #999;
}

/* 空状态样式 */
.empty-state {
  text-align: center;
  padding: 20px;
  color: #999;
  font-size: 14px;
}

/* 适配APP状态 */
.no-title {
  padding-top: 0;
}
</style>
