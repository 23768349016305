import axios from 'axios'
import store from '../store'

/**
 * 用户数据访问对象
 */
const DoctorUserDao = {
  /**
   * 转换性别显示
   * @param {number} sex 性别代码(1:男, 2:女)
   * @returns {string} 性别显示文本
   */
  _formatGender (sex) {
    switch (sex) {
      case 1:
        return '男'
      case 2:
        return '女'
      default:
        return ''
    }
  },

  /**
   * 获取用户信息
   */
  async getDoctorUserInfo () {
    const url = '/cms/doctor/search/token'
    const httpResp = await axios.get(url, {
      params: {
        token: store.state.user.profile.token
      }
    })
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)

    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }

    // 转换性别显示
    return {
      ...data,
      gender: this._formatGender(data.sex)
    }
  }

}

export default DoctorUserDao
