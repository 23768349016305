<template>
  <div>
    <my-nav-bar v-if="showAppBar" :title="'训练方案列表'" back @onBackClick="onBack"/>
    <container-list :empty="empty" :empty-desc="'暂无训练方案'" :loading="loading&&(datas.length===0)">
      <div>
        <van-pull-refresh v-model="refreshing" @refresh="loadDatas">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="httpDatas"
          >
            <div v-for="(item,index) in datas" :key="index">
              <div style="padding: 16px" @click="onItemClick(item)">
                <div>
                  <!--                  <div v-if="index===0"-->
                  <!--                       style="color: #23724e;font-size: 20px;text-align: center;border-radius: 15px;width: fit-content;margin-bottom: 8px;font-weight: bolder">-->
                  <!--                    (最新方案)-->
                  <!--                  </div>-->
                </div>
                <div style="font-size: 18px;color: #606060">发送时间：{{ item.gmtCreate }}</div>
                <div style="font-size: 28px;margin-top: 8px">{{ item.plan.planTitle }}</div>
                <div style="font-size: 18px;color: #606060;margin-top: 16px">医生：{{ item.doctorName }}</div>
              </div>
              <van-divider/>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </container-list>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import ContainerList from '../../components/ContainerList'
import PlanDao from '../../network/PlanDao'
import DateUtils from '../../../../utils/DateUtils'

export default {
  name: 'PlanListPage',
  components: {
    ContainerList,
    MyNavBar
  },
  data () {
    return {

      empty: true,
      loading: true,
      finished: false,
      refreshing: false,
      datas: [],
      showAppBar: true,
      phone: ''
    }
  },
  mounted () {
    if (this.$route.query) {
      console.log('query', this.$route.query)
      if (this.$route.query.from === 'wxapp') {
        this.showAppBar = false
        this.phone = this.$route.query.phone
      }
    }
    this.loadDatas()
  },
  methods: {
    onBack () {
      if (this.$store.state.user.qrCodeHospital === '宏乾健康') {
        this.$router.push('/hongqian/home')
      } else {
        this.$router.back()
      }
    },
    onItemClick (item) {
      if (this.$route.query.from === 'wxapp') {
        /*
        uni.navigateTo({
    url: '/pages/plan_detail/plan_detail?id=' + planItem.id,
  })
         */
        /*
        const wxJson = JSON.stringify(this.paySignInfo)
          const encodedParam = encodeURIComponent(wxJson)
         */
        // window.wx.miniProgram.navigateTo({
        //   url: '/pages/plan_detail/plan_detail?id=' + item.id
        // })
        this.$store.commit('play/setPlan', item)
        this.$router.push('/PlanDetailPage')
      } else {
        this.$store.commit('play/setPlan', item)
        this.$router.push('/PlanDetailPage')
      }
    },
    async httpDatas () {
      let datas = await PlanDao.loadList(this.phone ? this.phone : this.$store.state.user.profile.phone)

      if (!datas || datas.length === 0) {
        datas = await PlanDao.loadList('11900009101')
      }

      datas.forEach(e => {
        e.plan = JSON.parse(e.plan)
        e.gmtCreate = DateUtils.formate(e.gmtCreate, 'yyyy-MM-DD HH:mm:ss')
        console.log(e.plan)
      })
      this.datas = datas
    },
    async loadDatas () {
      this.loading = true
      this.refreshing = true
      await this.httpDatas()
      console.log('数据请求结果', this.datas)
      if (this.datas.length) {
        this.empty = false
      } else {
        this.empty = true
      }
      this.finished = true
      this.loading = false
      this.refreshing = false
    }
  }
}
</script>

<style scoped>

</style>
